function makePlacesAsKeys(selectedPlaces) {
    let places = selectedPlaces.map(item => item.Place)
    let res =  selectedPlaces.reduce((acc, item) => {
        let {Place, ...rest} = item;
        const match = Place.match(/\d+[A-Za-zА-Яа-я]/);
        if (match) {
            let num = parseInt(match[0])
            if (places.includes(num.toString())) {
                Place = (num + 1).toString()
            } else {
                Place = num.toString()
            }
        }
        acc[Place] = rest;
        return acc;
    }, {});
    console.log('makePlacesAsKeys', res)
    return res
}

function checkSimilarityOfClasses(selectedPlaces) {
    let state = false
    const places = Object.keys(selectedPlaces)
    let previousClass = selectedPlaces[places[0]].ServiceClass
    for (let i = 1; i < places.length; i++) {
        let particularPlace = selectedPlaces[places[i]]
        if (previousClass === particularPlace.ServiceClass) {
            previousClass = particularPlace.ServiceClass
            state = true
        } else {
            return false
        }
    }
    return state
}

function isSortableArray(arr) {
    let state = false;

    if (arr.length <= 1) {
        return state;
    }

    const sortedArray = [...arr].map(Number).sort((a, b) => a - b);

    let first_num = sortedArray[0];
    for (let i = 1; i < sortedArray.length; i++) {
        let item = sortedArray[i];
        if (item - 1 === first_num) {
            first_num = item;
            state = true;
        } else {
            return false;
        }
    }

    return state;
}


export function prepareInfoForReservation(dto, placesTo, placesReturn, carsInfo) {
    placesTo = makePlacesAsKeys(placesTo);
    placesReturn = makePlacesAsKeys(placesReturn);

    let customer = {
        "Index": null,
        "DocumentType": null,
        "DocumentNumber": null,
        "CitizenshipCode": null,
        "LastName": null,
        "FirstName": null,
        "MiddleName": null,
        "Sex": null,
        "Birthday": null,
        "isSchoolkid": null
    }
    let reservationItem = {
        'Index': null,
        'Origin': null,
        'Destination': null,
        'DepartureDate': null,
        'TrainNumber': null,
        'CarNumber': null,
        'CarType': null,
        'PlaceFrom': null,
        'PlaceTo': null,
        'ServiceClass': null,
        'InternationalServiceClass': null,
        'PlacesRequestType': null,
        'Provider': null,
        'Passengers': null,
        'Gender': null,
        'CarPlaceType': null,
        'isBedClothes': null,
        'WithPets': null,
        'HasElectronicRegistration': null
    }

    let preparedInfo = {
        'Customers': [],
        'ReservationItems': [],
        'Buyer': dto.Buyer,
    }

    // Customers adding block
    Object.values(dto.Customers).forEach(item => {
        let customerCopy = JSON.parse(JSON.stringify(customer));
        customerCopy.Index = item.Index
        customerCopy.DocumentType = item.DocumentType
        customerCopy.DocumentNumber = item.DocumentNumber
        customerCopy.CitizenshipCode = item.CitizenshipCode
        customerCopy.LastName = item.LastName
        customerCopy.FirstName = item.FirstName
        customerCopy.MiddleName = item.MiddleName
        customerCopy.Sex = item.Sex
        customerCopy.Birthday = item.Birthday
        customerCopy.isSchoolkid = item.isSchoolkid
        preparedInfo.Customers.push(customerCopy)
    })


    // ReservationItems adding block
    const returnSelected = Object.keys(placesReturn).length !== 0

    let places = []
    if (returnSelected) {
        places.push(Object.keys(placesTo))
        places.push(Object.keys(placesReturn))
    } else {
        places.push(Object.keys(placesTo))
    }
    let reservationItemIndex = 0

    function splitByTypeOfPlace(toArr, returnArr) {
        const allPlaces = [toArr, returnArr]

        let res = {
            forward: {
                TwoPlacesAtOnce: [],
                FourPlacesAtOnce: [],
                MotherAndBaby: [],
                WithChild: [],
                Usual: []
            },
            backward: {
                TwoPlacesAtOnce: [],
                FourPlacesAtOnce: [],
                MotherAndBaby: [],
                WithChild: [],
                Usual: []
            }
        }

        allPlaces.forEach((places, index) => {
            if (Object.keys(returnArr).length === 0) {
                res.backward = null
            }

            Object.keys(places).forEach((value) => {
                const place = places[value]
                if (place.CarPlaceType === 'MotherAndBaby') {
                    index === 1 ? res.backward.MotherAndBaby.push(value) : res.forward.MotherAndBaby.push(value)
                } else if (place.CarPlaceType === 'WithChild') {
                    index === 1 ? res.backward.WithChild.push(value) : res.forward.WithChild.push(value)
                } else if (place.PlaceReservationType === 'TwoPlacesAtOnce') {
                    index === 1 ? res.backward.TwoPlacesAtOnce.push(value) : res.forward.TwoPlacesAtOnce.push(value)
                } else if (place.PlaceReservationType === 'FourPlacesAtOnce') {
                    index === 1 ? res.backward.FourPlacesAtOnce.push(value) : res.forward.FourPlacesAtOnce.push(value)
                } else {
                    index === 1 ? res.backward.Usual.push(value) : res.forward.Usual.push(value)
                }
            })

        })
        Object.keys(res).forEach(key => {
            for (const typeOfPlace in res[key]) {
                if (Array.isArray(res[key][typeOfPlace]) && res[key][typeOfPlace].length === 0) {
                    delete res[key][typeOfPlace];
                }
            }

        })

        return res
    }

    function prepareReservation(places, isReturn, singleItem, direction, dto) {

        let babiesWithoutPlaces = Object.values(dto.Passengers).filter(item => item.Category === 'BabyWithoutPlace')
        
        babiesWithoutPlaces = Object.values(JSON.parse(JSON.stringify(babiesWithoutPlaces))).map((value, index) => {
            value['PreferredAdultTariffType'] = direction === 'forward' ? value.Tariff.forward : value.Tariff.backward
            value['HasNonRefundableTariff'] = direction === 'forward' ? value.NonRefundableTariffs.forward : value.NonRefundableTariffs.backward
            return value
        })
        let arrayOfPlaces = places
        let reservationItemGeneral = JSON.parse(JSON.stringify(reservationItem))
        reservationItemGeneral.Origin = !isReturn ? carsInfo.forward.Origin : carsInfo.backward.Origin
        reservationItemGeneral.Destination = !isReturn ? carsInfo.forward.Destination : carsInfo.backward.Destination
        reservationItemGeneral.DepartureDate = !isReturn ? carsInfo.forward.DepartureDate : carsInfo.backward.DepartureDate
        reservationItemGeneral.TrainNumber = !isReturn ? carsInfo.forward.Car.TrainNumber : carsInfo.backward.Car.TrainNumber
        reservationItemGeneral.CarNumber = !isReturn ? carsInfo.forward.Car.CarNumber : carsInfo.backward.Car.CarNumber
        reservationItemGeneral.CarType = !isReturn ? carsInfo.forward.Car.CarType : carsInfo.backward.Car.CarType
        reservationItemGeneral.InternationalServiceClass = !isReturn ? carsInfo.forward.Car.InternationalServiceClass : carsInfo.backward.Car.InternationalServiceClass
        reservationItemGeneral.CarType = !isReturn ? carsInfo.forward.Car.CarType : carsInfo.backward.Car.CarType
        reservationItemGeneral.Provider = !isReturn ? carsInfo.forward.Provider : carsInfo.backward.Provider
        // reservationItemGeneral.Provider = 'P1'
        reservationItemGeneral.isBedClothes = !isReturn ? carsInfo.forward.Car.isBedClothes : carsInfo.backward.Car.isBedClothes
        reservationItemGeneral.HasElectronicRegistration = !isReturn ? carsInfo.forward.Car.HasElectronicRegistration : carsInfo.backward.Car.HasElectronicRegistration

        if ((isSortableArray(arrayOfPlaces) && checkSimilarityOfClasses(arrayOfPlaces) || singleItem)) {
            reservationItemGeneral.Index = reservationItemIndex
            reservationItemIndex++
            reservationItemGeneral.Passengers = Object.values(JSON.parse(JSON.stringify(dto.Passengers))).map((value, index) => {
                value['PreferredAdultTariffType'] = direction === 'forward' ? value.Tariff.forward : value.Tariff.backward
                value['HasNonRefundableTariff'] = direction === 'forward' ? value.NonRefundableTariffs.forward : value.NonRefundableTariffs.backward
                return value
            })
            reservationItemGeneral.PlaceFrom = Math.min(...arrayOfPlaces)
            reservationItemGeneral.PlaceTo = Math.max(...arrayOfPlaces)
            reservationItemGeneral.CarPlaceType = !isReturn ? placesTo[arrayOfPlaces[0]].CarPlaceType : placesReturn[arrayOfPlaces[0]].CarPlaceType
            reservationItemGeneral.WithPets = !isReturn ? placesTo[arrayOfPlaces[0]].WithPets : placesReturn[arrayOfPlaces[0]].WithPets
            reservationItemGeneral.ServiceClass = !isReturn ? placesTo[arrayOfPlaces[0]].ServiceClass : placesReturn[arrayOfPlaces[0]].ServiceClass
            reservationItemGeneral.Gender = !isReturn ? placesTo[arrayOfPlaces[0]].Gender : placesReturn[arrayOfPlaces[0]].Gender
            reservationItemGeneral.PlacesRequestType = !isReturn ? placesTo[arrayOfPlaces[0]].PlacesRequestType : placesReturn[arrayOfPlaces[0]].PlacesRequestType
            preparedInfo.ReservationItems.push(reservationItemGeneral)
        } else {
            const particularPlaces = isReturn ? placesReturn : placesTo
            arrayOfPlaces.forEach((place, index) => {
                let reservationItemAdditional = JSON.parse(JSON.stringify(reservationItemGeneral));
                reservationItemAdditional.Index = reservationItemIndex
                reservationItemIndex++
                reservationItemAdditional.PlaceFrom = place
                reservationItemAdditional.PlaceTo = place
                reservationItemAdditional.CarPlaceType = particularPlaces[place].CarPlaceType
                reservationItemAdditional.WithPets = particularPlaces[place].WithPets
                reservationItemAdditional.ServiceClass = particularPlaces[place].ServiceClass
                reservationItemAdditional.Gender = particularPlaces[place].Gender
                reservationItemAdditional.PlacesRequestType = particularPlaces[place].PlacesRequestType
                let OrderCustomerIndex = null
                Object.values(dto.Customers).forEach(item => {
                    let placeToDiffer = !isReturn ? item.Places.forward : item.Places.backward
                    if (parseInt(placeToDiffer) === parseInt(place)) {
                        OrderCustomerIndex = item.Index
                    }
                })
                let passengers = Object.values(JSON.parse(JSON.stringify(dto.Passengers))).map((value, index) => {
                    value['PreferredAdultTariffType'] = direction === 'forward' ? value.Tariff.forward : value.Tariff.backward
                    value['HasNonRefundableTariff'] = direction === 'forward' ? value.NonRefundableTariffs.forward : value.NonRefundableTariffs.backward
                    return value
                })
                reservationItemAdditional.Passengers = passengers.filter(item => item.OrderCustomerIndex === OrderCustomerIndex)
                reservationItemAdditional.Passengers.forEach(item => {
                    if (item.Category === 'Adult' && babiesWithoutPlaces.length !== 0) {
                        let last_baby = babiesWithoutPlaces.pop()
                        reservationItemAdditional.Passengers.push(last_baby)
                    }
                })
                preparedInfo.ReservationItems.push(reservationItemAdditional)
            })

        }
    }
    

    const sortedPlaces = splitByTypeOfPlace(placesTo, placesReturn)

    Object.keys(sortedPlaces).forEach(direction => {
        const isReturn = (sortedPlaces[direction] !== null) && (direction === 'backward') // ебать тупая тупость, нужно переписать :)
        const placesViaDirection = sortedPlaces[direction]

        for (const typeOfPlace in sortedPlaces[direction]) {
            let single = false
            if (typeOfPlace !== 'Usual') {
                single = true
            }
            prepareReservation(sortedPlaces[direction][typeOfPlace], isReturn, single, direction, dto)
        }
    })


    return preparedInfo
}