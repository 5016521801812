import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

const getPadTime = (time) => time.toString().padStart(2, "0");
export const Timer = ({ confirmTill, onTimeIsOver }) => {
  const [timer, setTimer] = useState(10);
  var moment = require("moment-timezone");

  const minutes = getPadTime(Math.floor(timer / 60));
  const seconds = getPadTime(timer - Number(minutes) * 60);

  useEffect(() => {
    const nowTz = moment.tz.guess();

    const now = moment().tz(nowTz).format("YYYY-MM-DDTHH:mm:ss");

    const end = moment
      .tz(confirmTill, "YYYY-MM-DDTHH:mm:ss", "Europe/Moscow")
      .tz(nowTz);

    const diff = moment.duration(end.diff(now)).asSeconds();
    setTimer(diff);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      onTimeIsOver();
    }
    const interval = setInterval(() => {
      setTimer((timer) => (timer >= 1 ? timer - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <>
      <h3>{minutes + ":" + seconds + " Время бронирования"}</h3>
    </>
  );
};
