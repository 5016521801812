import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { FormContext } from "../ReservationForm/ReservationForm";

export const GenderCheckbox = (props) => {
  const [sex, setSex] = useState("");
  const { formId, babyId } = useContext(FormContext);
  const setSexHandler = (e) => {
    props.onChange(e);
    setSex(e.target.value);
  };

  return (
    <div className="gender-radio">
      <Form.Check
        id={props.id}
        required
        disabled={(props.forwardGender === "Female" || props.backwardGender === "Female") && props.category === 'Adult'}
        label="Мужской"
        value="Male"
        type="radio"
        checked={sex === "Male"}
        name={`${props.name}-${props.id === "Adult" ? formId : babyId}`}
        onChange={(e) => setSexHandler(e)}
      />
      <Form.Check
        id={props.id}
        required
        disabled={(props.forwardGender === "Male" || props.backwardGender === "Male") && props.category === 'Adult'}
        value="Female"
        label="Женский"
        type="radio"
        checked={sex === "Female"}
        name={`${props.name}-${props.id === "Adult" ? formId : babyId}`}
        onChange={(e) => setSexHandler(e)}
      />
    </div>
  );
};
