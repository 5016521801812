import React, { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row } from "react-bootstrap";
import "../../Styles/Components/Train/Train.scss";
import { TrainTooltip } from "./TrainTooltip";
import { useNavigate } from "react-router-dom";
import { TrainInfo } from "./TrainInfo";
import {
  $isReturnPageStore,
  setLocalTrainData,
  setLocalTrainDataReturn,
  resetLocalTrainDataReturn,
} from "../../Services/trainsService";
import { useStore } from "effector-react";

export const Train = ({ trainData, destination, origin, state }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 992;
  const isReturnPage = useStore($isReturnPageStore);

  //Для отображния тултипа в зависимости от ширины устройства
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  //ФУНКЦИЯ ПЕРЕХОДА НА СТРАНИЦУ ВАГОНОВ
  const navigate = useNavigate();
  const handleSubmit = (CarType) => {
    if (isReturnPage) {
      setLocalTrainDataReturn(trainData);
    } else {
      resetLocalTrainDataReturn();
      setLocalTrainData(trainData);
    }
    navigate(
      `/cars/${trainData.OriginStationCode}/${trainData.DestinationStationCode}/${trainData.DepartureDateTime}/${trainData.TrainNumber}/${CarType}/${trainData.Provider}/0`,
      {
        state: {
          destination: destination,
          origin: origin,
          cityFrom: state.cityFrom,
          cityTo: state.cityTo,
          dateTo: state.dateTo,
          dateReturn: state.dateReturn,
        },
      }
    );
  };

  const carTypeName = (CarType) => {
    switch (CarType) {
      case "СВ":
        return "СВ";
      case "ЛЮКС":
        return "Люкс";
      case "КУПЕ":
        return "Купе";
      case "ПЛАЦ":
        return "Плацкарт";
      case "СИД":
        return "Сидячие";
      case "ОБЩ":
        return "Общий";
    }
  };

  return (
    <div className="train">
      <TrainInfo trainData={trainData} />
      <Col className="train__car-type">
        <Row className="train__car-type-items">
          <span className="train-submit">выберите тип вагона:</span>

          {trainData.CarGroups.map((CarGroup, i) => (
            <Row key={i.toString()} className="train__car-type-items-item">
              <OverlayTrigger
                data-trigger="hover"
                placement={isMobile ? "top" : "left"}
                overlay={TrainTooltip(CarGroup)}
              >
                {CarGroup.CarTypeName.toUpperCase() !== "БАГАЖ" ? (
                  <Button
                    key={i}
                    className={
                      CarGroup.CarTypeName.toUpperCase() === "КУПЕ" ||
                      CarGroup.CarTypeName.toUpperCase() === "СВ" ||
                      CarGroup.CarTypeName.toUpperCase() === "ЛЮКС"
                        ? "train__car-btn train__car-btn-prime"
                        : "train__car-btn"
                    }
                    onClick={() => handleSubmit(CarGroup.CarType)}
                  >
                    {carTypeName(CarGroup.CarTypeName)}-
                    {CarGroup.TotalPlaceQuantity}
                    <div className="train__car-type-price">
                      {" "}
                      от {CarGroup.MinPrice} ₽
                    </div>
                  </Button>
                ) : (
                  <></>
                )}
              </OverlayTrigger>
            </Row>
          ))}
        </Row>
      </Col>
    </div>
  );
};
