import React from "react";
import "../../Styles/Components/ProgressBar.scss";

const ProgressBar = () => {
  return (
    <div className="progress-bar">
      <div className="title">
        <ul>
          <li>Поезда</li>
          <li>Места</li>
          <li>Пассажиры</li>
          <li>Подтверждение</li>
          <li>Оплата</li>
        </ul>
      </div>
    </div>
  );
};

export default ProgressBar;
