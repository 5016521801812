import React from "react";
import { Col, Popover, PopoverBody, PopoverHeader, Row } from "react-bootstrap";

export const TrainTooltip = (group) => {
  const carTypeName = (CarType) => {
    switch (CarType) {
      case "СВ":
        return "СВ";
      case "ЛЮКС":
        return "Люкс";
      case "КУПЕ":
        return "Купе";
      case "ПЛАЦ":
        return "Плацкарт";
      case "СИД":
        return "Сидячие";
    }
  };

  return (
    <Popover id="popover-basic">
      <PopoverHeader as="h3">{carTypeName(group.CarTypeName)}</PopoverHeader>
      <PopoverHeader as="p">
        Доступно {group.TotalPlaceQuantity} мест
      </PopoverHeader>
      <PopoverBody>
        {group.LowerSidePlaceQuantity > 0 ? (
          <Row>
            <Col style={{ fontSize: "12px" }} className="col-4">
              Нижнее боковое
            </Col>
            <Col style={{ fontSize: "12px" }}>
              {group.LowerSidePlaceQuantity} мест от {group.MinPrice} &#8381;
            </Col>
          </Row>
        ) : (
          ""
        )}
        {group.UpperSidePlaceQuantity > 0 ? (
          <Row>
            <Col style={{ fontSize: "12px" }} className="col-4">
              Верхнее боковое
            </Col>
            <Col style={{ fontSize: "12px" }}>
              {" "}
              {group.UpperSidePlaceQuantity} мест от {group.MinPrice} &#8381;
            </Col>
          </Row>
        ) : (
          ""
        )}
        {group.LowerPlaceQuantity > 0 ? (
          <Row>
            <Col style={{ fontSize: "12px" }} className="col-4">
              Нижнее
            </Col>
            <Col style={{ fontSize: "12px" }}>
              {group.LowerPlaceQuantity} мест от {group.MinPrice} &#8381;
            </Col>
          </Row>
        ) : (
          ""
        )}
        {group.UpperPlaceQuantity > 0 ? (
          <Row>
            <Col style={{ fontSize: "12px" }} className="col-4">
              Верхнее
            </Col>
            <Col style={{ fontSize: "12px" }}>
              {group.UpperPlaceQuantity} мест от {group.MinPrice} &#8381;
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row className="mt-4">Цена указана на одного пассажира</Row>
      </PopoverBody>
    </Popover>
  );
};
