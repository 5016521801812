import React, { Children, useEffect, useState } from "react";
import "../../Styles/Components/LuxuryComponent.scss";

const LuxuryComponent = ({
  places,
  reservationType,
  formParam,
  setFormParam,
  carrier,
  serviceClass,
  carType,
}) => {
  const [totalSeats, setTotalSeats] = useState(places);
  const [adultsAdded, setAdultsAdded] = useState(0);
  const [adultSeatsAdded, setAdultSeatsAdded] = useState(0);
  const [childrenAdded, setChildrenAdded] = useState(0);
  const [childSeatsAdded, setChildSeatsAdded] = useState(0);
  const [childrenWithoutSeatAdded, setChildrenWithoutSeatAdded] = useState(0);
  const [isChildWithoutPlaceAvailable, setIsChildWithoutPlaceAvailable] =
    useState(false);
  const [adultsData, setAdultsData] = useState([]); // Массив для хранения данных о добавленных взрослых
  const [childrenData, setChildrenData] = useState([]); // Массив для хранения данных о добавленных детях, занимающих место
  const [forwardNonReturnableTariff, setForwardNonReturnableTariff]= useState(false)
  const [backwardNonReturnableTariff,  setBackwardNonReturnableTariff]= useState(false)


  useEffect(() => {
    console.log(formParam[0])
    setForwardNonReturnableTariff(formParam[0].forwardNonReturnableTariff)
    setBackwardNonReturnableTariff(formParam[0].backwardNonReturnableTariff)
    setFormParam([]);
  }, []);

  // Функция для генерации уникальных идентификаторов
  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const isSeatAvailable = () => {
    return totalSeats > 0;
  };

  const handleAddAdult = () => {
    let updatedParam = [
      ...formParam,
      {
        category: "Adult",
        forward: null,
        backward: null,
        forwardNonReturnableTariff: forwardNonReturnableTariff,
        backwardNonReturnableTariff: backwardNonReturnableTariff,
        forwardGender: "NoValue",
        backwardGender: "NoValue",
        carType: carType,
      },
    ];
    setFormParam(updatedParam);
    if (isSeatAvailable()) {
      setAdultsAdded(adultsAdded + 1);
      setTotalSeats(totalSeats - 1);
      const newAdult = { id: generateUniqueId(), seats: 1 };
      setAdultsData([...adultsData, newAdult]);
    }
  };

  const handleAddAdultSeat = (id) => {
    setChildrenWithoutSeatAdded(0);
    const updatedAdultsData = adultsData.map((adult) => {
      if (adult.id === id) {
        return { ...adult, seats: adult.seats + 1 };
      }
      return adult;
    });

    setTotalSeats(totalSeats - 1);
    setAdultSeatsAdded(adultSeatsAdded + 1);
    setAdultsData(updatedAdultsData);
  };

  const handleAddChildWithSeat = () => {
    let updatedParam = [
      ...formParam,
      {
        category: "Child",
        forward: null,
        backward: null,
        forwardNonReturnableTariff: forwardNonReturnableTariff,
        backwardNonReturnableTariff: backwardNonReturnableTariff,
        forwardGender: "NoValue",
        backwardGender: "NoValue",
        carType: carType,
      },
    ];
    setFormParam(updatedParam);
    if (isSeatAvailable()) {
      setTotalSeats(totalSeats - 1);
      setChildrenAdded(childrenAdded + 1);
      const newChild = { id: generateUniqueId(), seats: 1 };
      setChildrenData([...childrenData, newChild]);
    }
  };

  const handleAddChildSeat = (id) => {
    setChildrenWithoutSeatAdded(0);
    const updatedChildrenData = childrenData.map((child) => {
      if (child.id === id) {
        return { ...child, seats: child.seats + 1 };
      }
      return child;
    });

    setTotalSeats(totalSeats - 1);
    setChildSeatsAdded(childSeatsAdded + 1);
    setChildrenData(updatedChildrenData);
  };

  const handleAddChildWithoutSeat = () => {
    let updatedParam = [
      ...formParam,
      {
        category: "BabyWithoutPlace",
        forward: null,
        backward: null,
        forwardNonReturnableTariff: false,
        backwardNonReturnableTariff: false,
        forwardGender: "NoValue",
        backwardGender: "NoValue",
        carType: carType,
      },
    ];
    const filteredAdultsPassengers = adultsData.filter(
      (passenger) => passenger.seats !== 0
    );
    const filteredChildrenPassengers = childrenData.filter(
      (passenger) => passenger.seats !== 0
    );
    const adults = filteredAdultsPassengers.length;
    const children = filteredChildrenPassengers.length;
    if (adults === 0) {
      return null;
    }
    const key = `${adults},${children}`;
    const casesForFour = {
      "1,0": 1,
      "2,0": 2,
      "1,1": 1,
      "3,0": 3,
      "2,1": 2,
      "1,2": 1,
      "4,0": 4,
      "3,1": 1,
      "2,2": 2,
      "1,3": 1,
    };
    let casesForTwo = {};

    if (["1А", "1М", "1И"].includes(serviceClass)) {
      casesForTwo = {
        "1,0": 2,
        "1,1": 1,
        "2,0": 1,
      };
      if (carrier !== "ГРАНД") {
        casesForTwo["2,0"] = 2;
      }
    }
    if (serviceClass === "1E") {
      casesForTwo = {
        "1,0": 1,
        "1,1": 1,
        "2,0": 2,
      };
    }

    if (reservationType === "TwoPlacesAtOnce") {
      if (childrenWithoutSeatAdded < casesForTwo[key]) {
        setFormParam(updatedParam);
        setChildrenWithoutSeatAdded(childrenWithoutSeatAdded + 1);
      } else {
        setChildrenWithoutSeatAdded(casesForTwo[key]);
        setIsChildWithoutPlaceAvailable(true);
      }
    }
    if (reservationType === "FourPlacesAtOnce") {
      if (childrenWithoutSeatAdded < casesForFour[key]) {
        setFormParam(updatedParam);
        setChildrenWithoutSeatAdded(childrenWithoutSeatAdded + 1);
      } else {
        setChildrenWithoutSeatAdded(casesForFour[key]);
        setIsChildWithoutPlaceAvailable(true);
      }
    }
  };

  return (
    <div className="passenger-form-page-preform__luxury-component">
      <h3>Выберите категорию пассажиров для каждого места</h3>
      <p className="luxury-component__seats">
        Оставшееся количество мест: {totalSeats}
      </p>
      <button
        className="luxury-component__button"
        onClick={handleAddAdult}
        disabled={!isSeatAvailable()}
      >
        Добавить взрослого пассажира
      </button>
      {adultsData.map((adult) => (
        <div key={adult.id} className="luxury-component__passenger-info">
          <p className={"luxury-component__number-selected"}>{adult.seats}</p>
          <p>Взрослый пассажир</p>
          <button
            className="luxury-component__button-small"
            onClick={() => handleAddAdultSeat(adult.id)}
            disabled={!isSeatAvailable()}
          >
            Добавить место
          </button>
        </div>
      ))}
      <button
        className="luxury-component__button"
        onClick={handleAddChildWithSeat}
        disabled={!isSeatAvailable()}
      >
        Добавить ребенка, занимающего место
      </button>
      {childrenData.map((child) => (
        <div key={child.id} className="luxury-component__passenger-info">
          <p className={"luxury-component__number-selected"}>{child.seats}</p>
          <p>Ребенок, занимающий место</p>
          <button
            className="luxury-component__button-small"
            onClick={() => handleAddChildSeat(child.id)}
            disabled={!isSeatAvailable()}
          >
            Добавить место
          </button>
        </div>
      ))}

      <button
        className="luxury-component__button"
        onClick={handleAddChildWithoutSeat}
        disabled={isChildWithoutPlaceAvailable}
      >
        Добавить ребенка без места: {childrenWithoutSeatAdded}
      </button>
    </div>
  );
};

export default LuxuryComponent;
