import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../Styles/Pages/StartPage/GuaranteesCarousel.scss";
import withoutRegistration from "../../../Assets/startPage/guarantees/withoutRegistration.png";
import safePayment from "../../../Assets/startPage/guarantees/safePayment.png";
import quickPurchase from "../../../Assets/startPage/guarantees/quickPurchase.png";
import support from "../../../Assets/startPage/guarantees/support.png";

const GuaranteesCarousel = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="guarantees-items">
      <div className="items-desktop">
        <div className="guarantees-item">
          <div className="item-img">
            <img src={withoutRegistration} alt="without registration" />
          </div>
          <p>Покупка билетов без регистрации</p>
        </div>
        <div className="guarantees-item">
          <div className="item-img">
            <img src={safePayment} alt="safe payment" />
          </div>
          <p>Безопасная система оплаты</p>
        </div>
        <div className="guarantees-item">
          <div className="item-img">
            <img src={quickPurchase} alt="quick purchase" />
          </div>
          <p>Быстрая покупка билетов</p>
        </div>
        <div className="guarantees-item">
          <div className="item-img">
            <img src={support} alt="support" />
          </div>
          <p>Чат-подержка 24/7</p>
        </div>
      </div>
      <div className="items-mobile">
        <Slider {...settings}>
          <div className="guarantees-item">
            <div className="item-img">
              <img src={withoutRegistration} alt="without registration" />
            </div>
            <p>Покупка билетов без регистрации</p>
          </div>
          <div className="guarantees-item">
            <div className="item-img">
              <img src={safePayment} alt="safe payment" />
            </div>
            <p>Безопасная система оплаты</p>
          </div>
          <div className="guarantees-item">
            <div className="item-img">
              <img src={quickPurchase} alt="quick purchase" />
            </div>
            <p>Быстрая покупка билетов</p>
          </div>
          <div className="guarantees-item">
            <div className="item-img">
              <img src={support} alt="support" />
            </div>
            <p>Чат-подержка 24/7</p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default GuaranteesCarousel;
