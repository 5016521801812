import React, { useEffect } from "react";
import { RailwayForm } from "../../Components/RailwayForm/RailwayForm";
import {
  resetAllReservationParams,
  resetIsReturnPage,
  resetPlaces,
  resetReservationItems,
  setIsStartPage,
} from "../../Services/trainsService";
import { Accordion, Form, Tabs, Tab } from "react-bootstrap";
import Carousel from "./BlogCarousel/BlogCarousel";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import "../../Styles/Pages/StartPage/StartPage.scss";
import GuaranteesCarousel from "./GuaranteesCarousel/GuaranteesCarousel";
import email from "../../Assets/startPage/email.png";
import train from "../../Assets/startPage/train.svg";
import airplane from "../../Assets/startPage/airplane.svg";

//Внутри компонета RailwayForm осуществляется переход на следующую страницу
export const StartPage = () => {
  useEffect(() => {
    setIsStartPage(true);
    resetPlaces();
    resetIsReturnPage();
    resetPlaces();
    resetReservationItems();
  }, []);

  return (
    <div className="start-page">
      <div className="start-page__main-screen">
        <Tabs defaultActiveKey="railway" transition={false} justify>
          <Tab
            eventKey="railway"
            title={
              <div className="start-page__main-screen-nav-title">
                <p>ж/д билеты</p>
                <img src={train} alt="поезд" />
              </div>
            }
          >
            <RailwayForm />
          </Tab>
          <Tab
            eventKey="plane"
            title={
              <div className="start-page__main-screen-nav-title">
                <p>авиабилеты</p>
                <img src={airplane} alt="самолет" />
              </div>
            }
          >
            <RailwayForm />
          </Tab>
        </Tabs>
      </div>
      <div className="start-page__blog-answers-section">
        <div className="start-page__blog-answers-section-blog">
          <h2 className="start-page__blog-answers-section-blog-header">
            Читай наш блок
          </h2>
          <Carousel />
        </div>
        <div className="start-page__blog-answers-section-answers">
          <div className="start-page__blog-answers-section-answers-content">
            <h2 className="start-page__blog-answers-section-answers-content-header">
              Вопросы и ответы, которые помогут вам
            </h2>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Где можно получить купленный билет?
                </Accordion.Header>
                <Accordion.Body>
                  После оплаты вашей покупки билет придет вам на почтовый адрес,
                  который был указан при заполнении формы.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Как расчитать наилучший период для покупки билетов по выгодной
                  цене?
                </Accordion.Header>
                <Accordion.Body>
                  Вы можете подписаться на нашу рассылку по электронной почте,
                  которая будет оповещать о лучших периодах для покупки билетов.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Имеются ли бонусы, скидки или акции на сервисе?
                </Accordion.Header>
                <Accordion.Body>
                  Да разумеется. Следите за новостями на сайте и в наших
                  социальных сетях. Получайте промокоды и приятные бонусы.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="start-page__guarantees-section">
        <GuaranteesCarousel />
      </div>
      <div className="start-page__subscribe-section">
        <Form className="start-page__subscribe-section-form">
          <div className="start-page__subscribe-section-form-img">
            <img src={email} alt="email" />
          </div>
          <Form.Control
            className="start-page__subscribe-section-form-input"
            type="email"
            placeholder="Адрес почты"
          />
          <p className="start-page__subscribe-section-form-description">
            Подпишись на нас, чтобы быть всегда в курсе новостей, выгодных акций
            и скидок.
          </p>
        </Form>
      </div>
    </div>
  );
};
