import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { StartPage } from "./Pages/StartPage/StartPage";
import { Route, Routes } from "react-router-dom";
import { TrainsPage } from "./Pages/TrainsPage";
import { CarsPage } from "./Pages/CarsPage";
import { SvgCarScheme } from "./Components/SvgCarScheme/SvgCarScheme";
import { PassengerFormPage } from "./Pages/PassengerFormPage";
import { ReservationPage } from "./Pages/ReservationPage";
import { RefundPage } from "./Pages/RefundPage";
import { ConfirmRefundPage } from "./Pages/ConfirmRefundPage";
import React from "react";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import { ErrorDialog } from "./Components/dialogs/ErrorDialog";

function App() {
  return (
    <div className="App">
      <Header />
      <ErrorDialog />

      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route
          path="/trains/from/:Origin/to/:Destination/date/:DepartureDate"
          element={<TrainsPage />}
        />
        <Route
          path="/cars/:Origin/:Destination/:DepartureDate/:TrainNumber/:CarType/:Provider"
          element={<CarsPage />}
        >
          <Route path=":CarId" element={<SvgCarScheme />} />
        </Route>
        <Route path="/passengerForm" element={<PassengerFormPage />} />
        <Route path="/reservationPage" element={<ReservationPage />} />
        <Route path="/refundTickets" element={<RefundPage />} />
        <Route path="/confirmRefund" element={<ConfirmRefundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
