import React, { useEffect, useRef, useState } from "react";
import LuxuryComponent from "./LuxuryComponent";
import "../../Styles/Components/PreFormComponent.scss";

const PreFormComponent = ({
  setPreFormInfo,
  forward,
  backward,
  carrier,
  carType,
  provider,
}) => {
  if (backward.length === 0) {
    backward = null;
  }

  forward = forward.reduce((acc, item) => {
    const { Place, ...rest } = item;
    acc[Place] = rest;
    return acc;
  }, {});

  if (backward !== null) {
    backward = backward.reduce((acc, item) => {
      const { Place, ...rest } = item;
      acc[Place] = rest;
      return acc;
    }, {});
  }

  const PlaceReservationType =
    forward[Object.keys(forward)[0]].PlaceReservationType;
  const [carPlaceTypeMeaning, setCarPlaceTypeMeaning] = useState([]);

  const ServiceClass = forward[Object.keys(forward)[0]].ServiceClass;

  const availableSeats = {
    forward: Object.keys(forward),
    backward: backward !== null ? Object.keys(backward) : null,
  };

  const [error, setError] = useState("");
  const [restSeats, setRestSeats] = useState({ ...availableSeats });

  const divSelectionRef = useRef();
  const [refs, setRefs] = useState([]);

  const [preFormParam, setPreFormParam] = useState([]);

  //Категории пассажиров
  const categories = {
    Adult: "Взрослый",
    Child: "Ребенок",
    BabyWithoutPlace: "Ребенок без места",
  };

  //Начальное значение формы
  const initialFormParams = () => {
    return [
      {
        category: "Adult",
        forward: availableSeats.forward[0],
        backward:
          availableSeats.backward == null ? null : restSeats.backward[0],
        forwardNonReturnableTariff:
          forward[availableSeats.forward[0]]["HasNonRefundableTariff"],
        backwardNonReturnableTariff:
          availableSeats.backward == null
            ? false
            : backward[availableSeats.backward[0]]["HasNonRefundableTariff"],
        forwardGender: forward[availableSeats.forward[0]]["Gender"],
        backwardGender:
          availableSeats.backward == null
            ? false
            : backward[availableSeats.backward[0]]["Gender"],
        forwardCarPlaceType: forward[availableSeats.forward[0]].CarPlaceType,
        backwardCarPlaceType:
          restSeats.backward == null
            ? false
            : backward[availableSeats.backward[0]].CarPlaceType,
        carType: carType,
      },
    ];
  };
  const [formGenerationParams, setFormGenerationParams] = useState(
    initialFormParams()
  );

  const [
    selectedPlacesForParticularPassenger,
    setSelectedPlacesForParticularPassenger,
  ] = useState({
    forward: null,
    backward: null,
  });

  useEffect(() => {
    if (
      selectedPlacesForParticularPassenger.forward === null &&
      selectedPlacesForParticularPassenger.backward === null
    ) {
      let updateSelected = { ...selectedPlacesForParticularPassenger };
      updateSelected.forward = restSeats.forward[0];
      if (restSeats.backward !== null) {
        updateSelected.backward = restSeats.backward[0];
      }
      setSelectedPlacesForParticularPassenger(updateSelected);
    }
    let updateRefs = [...refs];
    updateRefs.push(divSelectionRef);
    setRefs(updateRefs);
  }, [formGenerationParams]);

  //Поиск мест со значением WithChild и MotherAndBaby
  useEffect(() => {
    const CarPlaceTypeMeaning = [];
    for (let i in forward) {
      if (
        forward[i].CarPlaceType === "WithChild" ||
        forward[i].CarPlaceType === "MotherAndBaby"
      ) {
        CarPlaceTypeMeaning.push(forward[i].CarPlaceType);
      }
    }
    if (backward !== null) {
      for (let i in backward) {
        if (
          backward[i].CarPlaceType === "WithChild" ||
          backward[i].CarPlaceType === "MotherAndBaby"
        ) {
          CarPlaceTypeMeaning.push(backward[i].CarPlaceType);
        }
      }
    }
    setCarPlaceTypeMeaning(
      CarPlaceTypeMeaning.filter(
        (item, index) => CarPlaceTypeMeaning.indexOf(item) === index
      )
    );
  }, []);

  //Отображение выбранных пользователем мест
  const selectedSeats = (item, index) => {
    const selectedSeat =
      item.backward === null
        ? item.category === "BabyWithoutPlace"
          ? `${index + 1}.${categories[item.category]}`
          : `${index + 1}.${categories[item.category]}: место №${item.forward}`
        : item.category === "BabyWithoutPlace"
        ? `$${categories[item.category]}`
        : `${index + 1}.${categories[item.category]}: туда - место №${
            item.forward
          };
          обратно - место №${item.backward}`;

    return <p key={index}>{selectedSeat}</p>;
  };

  //Все ли места выбраны
  const isEveryPlacesSelected = () => {
    return (
      preFormParam.map((item) => item.forward).length >=
        availableSeats.forward.length &&
      (availableSeats.backward === null
        ? true
        : preFormParam.map((item) => item.backward).length >=
          availableSeats.backward.length)
    );
  };

  const handleAddRow = () => {
    // здесь реализовать логику добавления до 4х человек
    if (checkForAdults() === checkForBabies() && checkForAdults() !== 0) {
      return null;
    }

    formGenerationParams.map((e) => {
      setPreFormParam([...preFormParam, e]);
    });

    let lastSelectRow = refs[refs.length - 1];
    if (lastSelectRow.current !== null) {
      lastSelectRow.current.hidden = true;
    }

    const emptySelectedSeats = { forward: null, backward: null };
    setSelectedPlacesForParticularPassenger(emptySelectedSeats);

    const updatedOptions = { ...restSeats };

    if (updatedOptions.backward !== null && !isEveryPlacesSelected()) {
      updatedOptions.backward = restSeats.backward.filter(
        (e) => e !== selectedPlacesForParticularPassenger.backward
      );
      updatedOptions.forward = restSeats.forward.filter(
        (e) => e !== selectedPlacesForParticularPassenger.forward
      );
    } else {
      updatedOptions.forward = restSeats.forward.filter(
        (e) => e !== selectedPlacesForParticularPassenger.forward
      );
    }

    setRestSeats(updatedOptions);

    setFormGenerationParams([
      ...formGenerationParams,
      updatedOptions.forward.length === 0 ||
      formGenerationParams[formGenerationParams.length - 1].category ===
        "BabyWithoutPlace"
        ? {
            category: "BabyWithoutPlace",
            forward: null,
            backward: null,
            forwardNonReturnableTariff: false,
            backwardNonReturnableTariff: false,
            forwardGender: "NoValue",
            backwardGender: "NoValue",
            forwardCarPlaceType: "NoValue",
            backwardCarPlaceType: "NoValue",
            carType: carType,
          }
        : {
            category: "Adult",
            forward: updatedOptions.forward[0],
            backward:
              updatedOptions.backward == null
                ? null
                : updatedOptions.backward[0],
            forwardNonReturnableTariff:
              forward[updatedOptions.forward[0]]["HasNonRefundableTariff"],
            backwardNonReturnableTariff:
              restSeats.backward == null
                ? false
                : backward[updatedOptions.backward[0]][
                    "HasNonRefundableTariff"
                  ],
            forwardGender: forward[updatedOptions.forward[0]]["Gender"],
            backwardGender:
              restSeats.backward == null
                ? false
                : backward[updatedOptions.backward[0]]["Gender"],
            forwardCarPlaceType:
              forward[updatedOptions.forward[0]].CarPlaceType,
            backwardCarPlaceType:
              restSeats.backward == null
                ? false
                : backward[updatedOptions.backward[0]].CarPlaceType,
            carType: carType,
          },
    ]);
  };

  //Проверка является ли пассажир взрослым
  const checkForAdults = () => {
    return preFormParam.filter((item) => item.category === "Adult").length;
  };

  //Проверка является ли пассажир ребенком без места
  const checkForBabies = () => {
    return preFormParam.filter((item) => item.category === "BabyWithoutPlace")
      .length;
  };

  //Сброс формы
  const handleReset = () => {
    window.location.reload(false);
  };

  //Сменить категорию пассажира
  const handleCategoryChange = (index, value) => {
    const updatedRows = [...formGenerationParams];
    updatedRows[index].category = value;
    setFormGenerationParams(updatedRows);
  };

  //Сменить места пассажиру
  const handleSeatChange = (index, forwardSelected, backwardSelected) => {
    const updatedRows = [...formGenerationParams];
    if (forwardSelected !== null) {
      updatedRows[index].forward = forwardSelected;
      updatedRows[index].forwardNonReturnableTariff =
        forward[forwardSelected]["HasNonRefundableTariff"];
      updatedRows[index].forwardGender = forward[forwardSelected]["Gender"];
    }
    if (backwardSelected !== null) {
      updatedRows[index].backward = backwardSelected;
      updatedRows[index].backwardNonReturnableTariff =
        backward[backwardSelected]["HasNonRefundableTariff"];
      updatedRows[index].backwardGender = backward[backwardSelected]["Gender"];
    }
    setSelectedPlacesForParticularPassenger({
      forward: updatedRows[index].forward,
      backward: updatedRows[index].backward,
    });
    setFormGenerationParams(updatedRows);
  };

  //Ограничения
  const additionalPlaceConditions = (item) => {
    const passengers = [];
    switch (item) {
      case "MotherAndBaby": {
        preFormParam.map((item) => {
          passengers.push(item.category);
        });
        return passengers.includes("BabyWithoutPlace");
      }
      case "WithChild": {
        preFormParam.map((item) => {
          passengers.push(item.category);
        });
        return (
          (passengers.filter((item) => item === "Adult").length === 2 ||
            passengers.filter((item) => item === "Adult").length === 1) &&
          (passengers.includes("BabyWithoutPlace") ||
            passengers.includes("Child"))
        );
      }
    }
  };

  const checkingAdditionalPlaceConditions = () => {
    if (carPlaceTypeMeaning.length === 0) {
      return true;
    } else {
      return additionalPlaceConditions(carPlaceTypeMeaning[0]);
    }
  };

  // Handle logic for next button click here
  const handleNext = () => {
    if (
      ["TwoPlacesAtOnce", "FourPlacesAtOnce"].includes(PlaceReservationType)
    ) {
      setPreFormInfo(formGenerationParams);
      console.log("preFormInfo", formGenerationParams);
    }
    if (restSeats.forward.length === 0 && checkingAdditionalPlaceConditions()) {
      setPreFormInfo(preFormParam);
      console.log("preFormInfo", preFormParam);
    } else {
      setError("Пожалуйста, правильно заполните форму!");
    }
  };

  const DefaultSelection = () => {
    return (
      <div className="passenger-form-page-preform__add-passengers">
        <h3>Выберите категорию пассажиров для каждого места</h3>
        <div className="add-passengers__added">
          {preFormParam.map((item, index) => selectedSeats(item, index))}
        </div>
        <div className="add-passengers__form">
          {formGenerationParams.map(
            (row, index) =>
              row.category !== "BabyWithoutPlace" && (
                <div key={index} ref={divSelectionRef}>
                  {index + 1}.{" "}
                  <select
                    className="select-category"
                    data-testid="switchCategory"
                    defaultValue={row.category}
                    onChange={(e) =>
                      handleCategoryChange(index, e.target.value)
                    }
                  >
                    <option value="Adult">{categories["Adult"]}</option>
                    <option value="Child">{categories["Child"]}</option>
                  </select>{" "}
                  <label htmlFor="forward">
                    {backward === null ? "место:" : "место туда:"}
                  </label>{" "}
                  <select
                    className="select-seats"
                    id="forward"
                    onChange={(e) =>
                      handleSeatChange(index, e.target.value, null)
                    }
                    defaultValue={restSeats.forward[0]}
                  >
                    {restSeats.forward.map((seatNumber, index) => (
                      <option value={seatNumber} key={index}>
                        {seatNumber}
                      </option>
                    ))}
                  </select>
                  {restSeats.backward === null ? (
                    <></>
                  ) : (
                    <>
                      <label
                        htmlFor="backward"
                        hidden={restSeats.backward == null}
                      >
                        обратно:
                      </label>{" "}
                      <select
                        className="select-seats"
                        id="backward"
                        onChange={(e) =>
                          handleSeatChange(index, null, e.target.value)
                        }
                        defaultValue={restSeats.forward[0]}
                      >
                        {restSeats.backward.map((seatNumber, index) => (
                          <option value={seatNumber} key={index}>
                            {seatNumber}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              )
          )}
          <div className="add-passengers__buttons">
            <button
              className="buttons__add-pessanger button"
              onClick={handleAddRow}
              hidden={isEveryPlacesSelected()}
            >
              Добавить пассажира
            </button>
            <button
              className="buttons__add-baby button"
              onClick={handleAddRow}
              hidden={
                !isEveryPlacesSelected() ||
                checkForAdults() === checkForBabies() ||
                provider === "P2"
              }
            >
              Добавить ребенка без места
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="passenger-form-page-preform">
        {["TwoPlacesAtOnce", "FourPlacesAtOnce"].includes(
          PlaceReservationType
        ) ? (
          <LuxuryComponent
            reservationType={PlaceReservationType}
            serviceClass={ServiceClass}
            formParam={formGenerationParams}
            setFormParam={setFormGenerationParams}
            places={availableSeats.forward.length}
            carrier={carrier}
            carType={carType}
          />
        ) : (
          DefaultSelection()
        )}
        <div
          className={
            ["TwoPlacesAtOnce", "FourPlacesAtOnce"].includes(
              PlaceReservationType
            )
              ? "passenger-form-page-preform__luxury-resetAndNext"
              : "passenger-form-page-preform__resetAndNext"
          }
        >
          <button className="button button__reset" onClick={handleReset}>
            Сбросить
          </button>
          <button className="button button__next" onClick={handleNext}>
            Далее
          </button>
        </div>
      </div>
      {error && (
        <div className="passenger-form-page-preform__error">{error}</div>
      )}
      {carPlaceTypeMeaning.length > 0 ? (
        <div className="passenger-form-page-preform__limitation">
          <h3>Обратите внимание, что у Вас выбраны места с условием:</h3>
          {carPlaceTypeMeaning.map((item, index) =>
            item === "MotherAndBaby" ? (
              <p key={index}>
                {index + 1}. Должет быть выбран один пассажир с ребенком до 1
                года (без занятия отдельного места)
              </p>
            ) : item === "WithChild" ? (
              <p key={index}>
                {index + 1}. Должен быть хотя бы один взрослый пассажир с
                ребенком до 10 лет и не более двух взрослых пассажиров
              </p>
            ) : (
              <></>
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PreFormComponent;
