import React from 'react';
import '../../Styles/Components/CarAndPlacesTable.scss';
import { Container, Table } from 'react-bootstrap';

export const CarAndPlacesTable = ({ reservationResult, customers }) => {
  const gendersList = [
    {
      key: 'Male',
      name: 'Мужской',
    },
    {
      key: 'Female',
      name: 'Женский',
    },
  ];

  const cabinGenderKind = [
    {
      key: 'Male',
      name: 'Мужское',
    },
    {
      key: 'Female',
      name: 'Женское',
    },
    {
      key: 'Whole',
      name: 'Целое',
    },
    {
      key: 'Mixed',
      name: 'Смешанное',
    },
    {
      key: '',
      name: '—',
    },
    {
      key: 'NoValue',
      name: '—',
    },
  ];

  const documentTypeKind = [
    { key: 'RussianPassport', name: 'Паспорт РФ' },
    { key: 'RussianForeignPassport', name: 'Заграничный паспорт РФ' },
    { key: 'BirthCertificate', name: 'Свидетельство о рождении' },
    { key: 'ForeignPassport', name: 'Иностранный документ' },
    { key: 'MilitaryCard', name: 'Военный билет' },
    {
      key: 'MilitaryOfficerCard',
      name: 'Удостоверение личности для военнослужащих',
    },
    { key: 'ReturnToCisCertificate', name: 'Свидетельство на возвращение' },
    { key: 'DiplomaticPassport', name: 'Дипломатический паспорт' },
    { key: 'ServicePassport', name: 'Служебный паспорт' },
    { key: 'SailorPassport', name: 'Удостоверение личности моряка' },
    {
      key: 'StatelessPersonIdentityCard',
      name: 'Удостоверение личности лица без гражданства',
    },
    { key: 'ResidencePermit', name: 'Вид на жительство' },
    {
      key: 'RussianTemporaryIdentityCard',
      name: 'Временное удостоверение личности',
    },
    { key: 'UssrPassport', name: 'Паспорт СССР' },
    {
      key: 'MedicalBirthCertificate',
      name: 'Медицинское свидетельство о рождении',
    },
    { key: 'LostPassportCertificate', name: 'Справка об утере паспорта' },
    {
      key: 'PrisonReleaseCertificate',
      name: 'Справка об освобождении из мест лишения свободы',
    },
    { key: 'RefugeeIdentity', name: 'Удостоверение беженца' },
    {
      key: 'RefugeeCertificate',
      name: 'Свидетельство о предоставлении временного убежища на территории РФ (ЖД)',
    },
    {
      key: 'ConvictedPersonIdentity',
      name: 'Документ, удостоверяющий личность осужденного',
    },
    {
      key: 'AcknowledgmentOfIdentityOfAForeignCitizenOrStatelessPerson',
      name: 'Заключение об установлении личности иностранного гражданина или лица без гражданства',
    },
    {
      key: 'CertificateIssuedForAForeignCitizenGoingToDiplomaticOffice',
      name: 'Справка для следования в дипломатическое представительство иностранного государства в Российской Федерации',
    },
    {
      key: 'AnotherDocumentOfAForeignCitizenRecognizedInTheRussianFederation',
      name: 'Иной документ, установленный федеральным законом или признаваемый в соответствии с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина и (или) лица без гражданства',
    },
  ];

  const placeTypeKind = [
    { key: 'MotherAndBaby', name: 'Место для матери и ребенка' },
    { key: 'WithChild', name: 'Места для пассажиров с детьми' },
    { key: 'WithPets', name: 'Места для пассажиров с животными' },
  ];

  const findType = placeType => {
    const type = placeTypeKind.find(place => place.key === placeType.Type);
    return type ? type.name : null;
  };

  return (
    <Container className="passenger-info">
      <p className="passenger-info__car-number">Вагон №{reservationResult.CarNumber}</p>
      {reservationResult.Passengers.map((passenger, i) => (
        <Table key={i} className="passenger-info__info-table ">
          <thead>
            <tr>
              <th className="passenger-info__info-table-header">Место</th>
              <th className="passenger-info__info-table-header">ФИО</th>
              <th className="passenger-info__info-table-header">Документ</th>
              <th className="passenger-info__info-table-header">Дата рождения</th>
              <th className="passenger-info__info-table-header">Пол</th>
              <th className="passenger-info__info-table-header">Цена билета</th>
              <th className="passenger-info__info-table-header">Тариф</th>
              <th className="passenger-info__info-table-header">Тип вагона и Класс обслуживания</th>
              <th className="passenger-info__info-table-header">Гендерный признак</th>
            </tr>
          </thead>
          <tbody>
            <tr className="passenger-info__info-table-collumns">
              <td className="passenger-info__info-table-col">
                <h5>Место</h5>
                <p>{passenger.Places}</p>
                <p className="additional-info">
                  {passenger.PlacesWithType !== null
                    ? passenger.PlacesWithType.map(placeType => findType(placeType))
                    : '—'}
                </p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>ФИО</h5>
                <p>{passenger.LastName + ' ' + passenger.FirstName + ' ' + passenger.MiddleName}</p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Документ</h5>
                <p>
                  {customers.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex).DocumentNumber}
                </p>
                <p className="additional-info">
                  {
                    documentTypeKind.find(
                      document =>
                        document.key ===
                        customers.find(customer => customer.Index === passenger.OrderCustomerReferenceIndex)
                          .DocumentType,
                    ).name
                  }
                </p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Дата рождения</h5>
                <p>
                  {passenger.Birthday.split('T')[0].split('-')[2] +
                    '.' +
                    passenger.Birthday.split('T')[0].split('-')[1] +
                    '.' +
                    passenger.Birthday.split('T')[0].split('-')[0]}
                </p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Пол</h5>
                <p>{gendersList.find(gender => gender.key === passenger.Sex).name}</p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Цена билета</h5>
                <p>{passenger.Amount}₽</p>
                {passenger.Category !== 'BabyWithoutPlace' && (
                  <p className="additional-info">
                    {`С учетом сервисного сбора 
                   ${
                     reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                       .ServicePrice
                   }₽`}
                  </p>
                )}
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Тариф</h5>
                <p>
                  {passenger.Category === 'BabyWithoutPlace'
                    ? reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                        .TariffInfo.TariffName === 'БЕЗДЕНЕЖНЫЙ ДЕТСКИЙ ДЕТ5'
                      ? 'ДЕТ5'
                      : '—'
                    : reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                        .TariffInfo.TariffName}
                </p>
                {reservationResult.Blanks.find(blank => blank.OrderItemBlankId === passenger.OrderItemBlankId)
                  .IsNonRefundableTariff &&
                  passenger.Category !== 'BabyWithoutPlace' && <p className="additional-info">Невозвратный</p>}
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Тип вагона и Класс обслуживания</h5>
                <p>
                  {reservationResult.CarTypeName} ({reservationResult.ServiceClass})
                </p>
              </td>
              <td className="passenger-info__info-table-col">
                <h5>Гендерный признак</h5>
                <p>{cabinGenderKind.find(gender => gender.key === reservationResult.CabinGenderKind).name}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      ))}
    </Container>
  );
};
