import React, { useEffect, useState } from "react";
import FormComponent from "../Components/ReservationForm/FormGenerator";
import { Button, Col, Form } from "react-bootstrap";
import axios from "axios";
import "../Styles/Pages/PassengerFormPage.scss";
import PreFormComponent from "../Components/ReservationForm/PreFormComponent";
import {
  $buyerId,
  $isReturnPageStore,
  $peakedPlacesToStore,
  $reservationItemsIfReturnStore,
  $localTrainInfoFromStore,
  $localTrainInfoReturnFromStore,
  $peakedPlacesReturnStore,
  $reservationItemsForwardBackwardStore,
} from "../Services/trainsService";
import { useStore } from "effector-react";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import { TrainInfo } from "../Components/Train/TrainInfo";
import { baseUrl } from "../config";
import { ReservationForm } from "../Components/ReservationForm/ReservationForm";
import { CustomModal } from "../Components/CustomModal/CustomModal";

export const PassengerFormPage = () => {
  const reservationItemsForwardBackward = useStore(
    $reservationItemsForwardBackwardStore
  );
  const [preFormInfo, setPreFormInfo] = useState(null);
  const [countiesList, setCountries] = useState([]);
  const placesTo = useStore($peakedPlacesToStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const allPlaces = [...placesTo, ...placesReturn];

  const isReturnPage = useStore($isReturnPageStore);
  const buyerId = useStore($buyerId);
  const trainData = useStore($localTrainInfoFromStore);
  const trainReturnData = useStore($localTrainInfoReturnFromStore);
  const navigate = useNavigate();
  const location = useLocation();
  const [carPlaceType, setCarPlaceType] = useState("");
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);

  const onCloseModalHandler = () => {
    setModal(false);
  };
  const [reservationDto, setReservationDto] = useState({
    Customers: {},
    ReservationItems: {},
  });

  useEffect(() => {
    window.removeEventListener("popstate", () => {});
    const hasWithChild = allPlaces.some(
      (element) => element.CarPlaceType === "WithChild"
    );
    if (hasWithChild) {
      setCarPlaceType("WithChild");
    }
  }, []);

  // ОТПРАВКА ДАННЫХ НА СЕРВЕР
  const onSubmitHandler = (event) => {
    const customerArr = Object.keys(reservationDto.Customers).map(
      (key) => reservationDto.Customers[key]
    );
    const reservationItems = Object.keys(reservationDto.ReservationItems).map(
      (key) => reservationDto.ReservationItems[key]
    );

    const navigateHandler = () => {
      navigate("/reservationPage", {
        state: {
          params: {
            Customers: customerArr,
            ReservationItems: reservationItems,
            Buyer: buyerId,
            Compartments: location.state.Compartments,
            PlaceReservationType: location.state.PlaceReservationType,
            Discounts: location.state.Discounts,
            HasElectronicRegistration: location.state.HasElectronicRegistration,
          },
          contactInfo: reservationItems.find((item) =>
            item.Passengers.find(
              (passenger) => passenger.OrderCustomerIndex === buyerId
            )
          ).Passengers[0],
        },
      });
    };

    if (carPlaceType === "WithChild") {
      event.preventDefault();
      const passengers = reservationItems.flatMap(
        ({ Passengers }) => Passengers
      );
      const hasChildPassenger = passengers.some(
        (passenger) => passenger.Category === "Child"
      );
      const hasAdultPassenger = passengers.some(
        (passenger) => passenger.Category === "Adult"
      );
      if (hasChildPassenger && hasAdultPassenger) {
        navigateHandler();
      } else {
        setModalMessage(
          "Для бронирования мест в детской зоне требуется как минимум один ребенок и один взрослый человек!"
        );
        setModal(true);
      }
    } else {
      navigateHandler();
    }
  };

  // //ФУНКЦИЯ ДЛЯ ПОЛУЧЕНИЯ СПИСКА СТРАН
  // const getCountries = () => {
  //   try {
  //     axios
  //       .get(`https://${baseUrl}/api/info/get_countries`)
  //       .then((response) => {
  //         setCountries(response.data);
  //       });
  //   } catch (error) {
  //     console.log("Ошибка при получении списка стран", error);
  //   }
  // };
  //
  // useEffect(() => {
  //   getCountries();
  // }, []);

  const calculateTotalPrice = () => {
    let totalMinPriceAndServiceCost = 0;

    if (
      allPlaces[0].PlaceReservationType === "TwoPlacesAtOnce" ||
      allPlaces[0].PlaceReservationType === "FourPlacesAtOnce"
    ) {
      totalMinPriceAndServiceCost +=
        allPlaces[0].MinPrice + allPlaces[0].ServiceCost;
    } else {
      allPlaces.forEach((item) => {
        totalMinPriceAndServiceCost += item.MinPrice + item.ServiceCost;
      });
    }

    return totalMinPriceAndServiceCost;
  };

  return (
    <div className="passenger-form-page">
      <CustomModal open={modal} onOpen={setModal} onClose={onCloseModalHandler}>
        <h2 style={{ color: "red" }}>Ошибка!</h2>
        <h4>{modalMessage}</h4>
      </CustomModal>
      <ProgressBar />
      <div className="passenger-form-page__content">
        {preFormInfo === null ? (
          <PreFormComponent
            setPreFormInfo={setPreFormInfo}
            forward={placesTo}
            п
            backward={placesReturn}
            carrier={reservationItemsForwardBackward.forward.Car.Carrier}
            carType={reservationItemsForwardBackward.forward.Car.CarType}
            provider={trainData.Provider}
          />
        ) : (
          <FormComponent
            totalPrice={calculateTotalPrice().toFixed(2)}
            preformInfo={preFormInfo}
            placeReservationType={placesTo[0].PlaceReservationType}
          />
        )}
      </div>
    </div>
  );
};
