import React, { useRef } from "react";
import Slider from "react-slick";
import "../../../Styles/Pages/StartPage/BlogCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderImg1 from "../../../Assets/startPage/sliderImg/sliderImg1.png";
import sliderImg2 from "../../../Assets/startPage/sliderImg/sliderImg2.png";
import sliderImg3 from "../../../Assets/startPage/sliderImg/sliderImg3.png";

const Carousel = () => {
  //Элементы слайдера
  const sliderItems = [
    {
      img: sliderImg2,
      description: "Как правильно организовать самостоятельное путешествие",
    },
    {
      img: sliderImg3,
      description: "Самые популярные направления туризма в России",
    },
    {
      img: sliderImg1,
      description: "Путешествие по великим местам. Елец, Россия",
    },
    {
      img: sliderImg2,
      description: "Как правильно организовать самостоятельное путешествие",
    },
    {
      img: sliderImg3,
      description: "Самые популярные направления туризма в России",
    },
    {
      img: sliderImg1,
      description: "Путешествие по великим местам. Елец, Россия",
    },
  ];

  //Настройки слайдера
  const settings = {
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    variableWidth: true,
    variableHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slider = useRef(null);

  return (
    <div className="carousel">
      <div
        className="carousel__prev-arrow carousel__arrow"
        onClick={() => slider?.current?.slickPrev()}
      >
        <svg
          width="23"
          height="53"
          viewBox="0 0 23 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M21 1L2 26L21 51.5" stroke="#FFC3B5" strokeWidth="3" />
        </svg>
      </div>
      <Slider ref={slider} {...settings}>
        {sliderItems.map((item, index) => (
          <div className="carousel__item" key={index}>
            <a
              href="##"
              className={
                index % 2 === 0
                  ? "carousel__item-body"
                  : "carousel__item-body carousel__item-body-reverse"
              }
            >
              <div className="item-img">
                <img src={item.img} alt="" />
              </div>
              <div className="carousel__item-description">
                {item.description}
              </div>
            </a>
          </div>
        ))}
      </Slider>
      <div
        className="carousel__next-arrow carousel__arrow"
        onClick={() => slider?.current?.slickNext()}
      >
        <svg
          width="23"
          height="53"
          viewBox="0 0 23 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 51.75L20.75 26.75L1.75 1.25"
            stroke="#FFC3B5"
            strokeWidth="3"
          />
        </svg>
      </div>
    </div>
  );
};

export default Carousel;
