import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $errorMessageStore } from '../../Services/trainsService';
import { AxiosError } from 'axios';
import { isArray, isObject } from 'lodash';
import { CustomModal } from '../CustomModal';

const getErrorResponseData = error => {
  let responseData;

  if (error instanceof AxiosError) {
    const { response } = error;

    if (response.status >= 500 && !isObject(response.data)) {
      responseData = { message: 'Сервис временно недоступен. Попробуйте позже' };
    } else {
      responseData = response.data
        ? isArray(response.data.detail)
          ? { message: response.data.detail[0].msg }
          : response.data
        : { message: 'Сервис временно недоступен' };
    }
  } else if (error instanceof Error) {
    const { message } = error;

    responseData = { message };
  } else {
    responseData = { message: 'Сервис временно недоступен' };
  }

  return responseData;
};

export const ErrorDialog = () => {
  const error = useStore($errorMessageStore);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  console.log({ error });
  useEffect(() => {
    if (!!error) {
      handleOpen();
    }
  }, [error]);

  const responseData = getErrorResponseData(error);

  return (
    <CustomModal open={isOpen} onClose={handleClose}>
      <div style={{ color: '#FF0000' }}>
        <h4>Сообщение:</h4>

        <p>
          {'message' in responseData
            ? responseData.message
            : 'detail' in responseData
            ? responseData.detail.Message
            : 'Message' in responseData
            ? responseData.Message
            : 'Сервис временно недоступен'}
        </p>
      </div>
    </CustomModal>
  );
};
