import React, { useEffect, useRef } from "react";
import "../../Styles/Components/LiveSearchInput.scss";

export const HardcodeDropdown = ({
  responseData, //Список элементов
  showDropdown, // Показать/Скрыть dropdown (состояние)
  onItemClickHandler, // Функция срабатывающая при нажатии на элемент списка
  onClickOutside, // Показать/Скрыть dropdown (функция)
  labelKey,
  valueKey,
}) => {
  const refTwo = useRef(null);

  //Добавление event-listener'а для закрытия Dropdown городов
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refTwo.current && !refTwo.current.contains(event.target)) {
        onClickOutside && onClickOutside(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  return (
    JSON.stringify(responseData) && (
      <div
        ref={refTwo}
        className={"responseDropDown " + (showDropdown ? "open" : "close")}
      >
        {responseData.map((responseItem, i) => (
          <div
            onClick={() => onItemClickHandler(responseItem)}
            className="responseItem col-12 justify-content-start"
            key={i}
          >
            {responseItem[labelKey]}
          </div>
        ))}
      </div>
    )
  );
};
