import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { PuffLoader } from 'react-spinners';
import { Train } from '../Components/Train/Train';
import { RailwayForm } from '../Components/RailwayForm/RailwayForm';
import '../Styles/Pages/TrainPage.scss';
import { useStore } from 'effector-react';
import {
  $isReturnPageStore,
  $modalErrorTogglerStore,
  setErrorMessage,
  setIsStartPage,
  dateReturnStoreSetter,
} from '../Services/trainsService';
import { baseUrl } from '../config';
//Страница Поездов

//Переход на страницу вагонов происходит в компоненте Train
export const TrainsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cityFrom, cityTo, cityFromCode, cityToCode, dateReturn, dateTo } = state;
  const [responseTrains, setResponseTrains] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const departureDate = new Date(dateTo);
  const departureDateReturn = new Date(dateReturn);
  const isReturnPage = useStore($isReturnPageStore);
  const errorModal = useStore($modalErrorTogglerStore);

  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ЗАГРУЗКИ ПОЕЗДОВ

  const getTrains = () => {
    const json = JSON.stringify(params);
    setIsLoaded(false);
    try {
      axios
        .post(`${baseUrl}/api/Railway/get_trains`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          setResponseTrains(response.data);
          setIsLoaded(true);
        })
        .catch(error => {
          setErrorMessage(error);
          navigate('/');
        });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (!errorModal) {
      getTrains(params);
    }
  }, [params]);

  useEffect(() => {
    setIsStartPage(false);
    dateReturnStoreSetter(dateReturn);
  }, []);

  return (
    <div className="train-list">
      <div className="train-list__items">
        <div className="train-list__change-direction">
          <RailwayForm
            isReturnPage={isReturnPage}
            cityFrom={cityFrom}
            cityTo={cityTo}
            cityFromCode={cityFromCode}
            cityToCode={cityToCode}
            dateReturnCode={dateReturn}
            dateToCode={dateTo}
            dateToPlaceholder={departureDate.toLocaleDateString('ru-ru')}
            dateReturnPlaceholder={dateReturn ? departureDateReturn.toLocaleDateString('ru-ru') : ''}
          />
        </div>
        {isLoaded ? (
          <div className="train-list__trains">
            <div className="train-list__trains-main-info">
              <h1>
                Список поездов по выбранному направлению {cityFrom}-{cityTo}
                {' ' + departureDate.toLocaleDateString('ru-ru')}
              </h1>
              <span>Вариантов: {responseTrains.Trains.length}</span>
            </div>
            {responseTrains.Trains.map((train, i) => (
              <div className="train-list__train" key={i}>
                <Train
                  destination={params.Destination}
                  origin={params.Origin}
                  trainData={train}
                  destinationParams={params}
                  state={state}
                />
              </div>
            ))}
          </div>
        ) : (
          <Container style={{ maxHeight: '100%' }} className="d-flex justify-content-center mt-5 pb-5">
            <PuffLoader color={'#000'} loading={!isLoaded} size={300} />
          </Container>
        )}
      </div>
    </div>
  );
};
