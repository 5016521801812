import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import "../Styles/Pages/CarsPage.scss";
import { useStore } from "effector-react";
import {
  $localCarPagePlaceStore,
  $peakedPlacesReturnStore,
  $peakedPlacesToStore,
  $reservationItemsIfReturnStore,
  $localTrainInfoFromStore,
  $localTrainInfoReturnFromStore,
  $isReturnPageStore,
  setCarInfoIfReturn,
  setErrorMessage,
  setIsReturnPage,
  $compartmentsForReservation,
  $placeReservationTypeStore,
  resetValidationForm,
  resetCompartments,
  setReservationItemsForward,
  setReservationItemsBackward,
} from "../Services/trainsService";
import { TrainInfo } from "../Components/Train/TrainInfo";
import ProgressBar from "../Components/ProgressBar/ProgressBar";
import { baseUrl } from "../config";
import Meal from "../Assets/ServiceIcons/Meal.svg";
import InfotainmentService from "../Assets/ServiceIcons/InfotainmentService.svg";
import Bedclothes from "../Assets/ServiceIcons/Bedclothes.svg";
import RestaurantCarOrBuffet from "../Assets/ServiceIcons/RestaurantCarOrBuffet.svg";
import HasPlacesNearPets from "../Assets/ServiceIcons/HasPlacesNearPets.svg";
import IsBeddingSelectionPossible from "../Assets/ServiceIcons/IsBeddingSelectionPossible.svg";
import { type } from "@testing-library/user-event/dist/type";
export const CarsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cityFrom, cityTo, dateReturn, dateTo, destination, origin } = state;
  const [responseCars, setResponseCars] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentCar, setCurrentCar] = useState(Number(params.CarId));
  const pickPlacesReturnStore = useStore($peakedPlacesReturnStore); //данные в стор вносятся в компоненте Svg внутри SvgCarScheme
  const localPagePlaces = useStore($localCarPagePlaceStore);
  const reservationItemsIfReturn = useStore($reservationItemsIfReturnStore);
  const placesTo = useStore($peakedPlacesToStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const trainData = useStore($localTrainInfoFromStore);
  const trainReturnData = useStore($localTrainInfoReturnFromStore);
  const isReturnPage = useStore($isReturnPageStore);
  const compartmentsStore = useStore($compartmentsForReservation);
  const reservationType = useStore($placeReservationTypeStore);
  const [minAmountPlaces, setMinAmountPlaces] = useState(0);

  const isAvailabilityIndication =
    currentCar.AvailabilityIndication === "Available";
  // console.log(state);

  const reservationItemsIfReturnArr = Object.keys(reservationItemsIfReturn).map(
    (key) => reservationItemsIfReturn[key]
  );
  const [isBedClothes, setIsBedClothes] = useState(true);
  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ЗАГРУЗКИ ВАГОНОВ

  useLayoutEffect(() => {
    if (responseCars) {
      setIsBedClothes(
        responseCars.Cars[currentCar].IsBeddingSelectionPossible ? true : null
      );
    }
  }, [responseCars]);
  const getCars = () => {
    const json = JSON.stringify(params);
    try {
      axios
        .post(`${baseUrl}/api/Railway/get_cars`, json, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setResponseCars(response.data);
          setIsLoaded(true);
        })
        .catch((error) => {
          setErrorMessage(error);
          navigate(-1);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = () => {
    resetValidationForm();
    if (dateReturn !== "") {
      if (isReturnPage === false) {
        setIsReturnPage(true);
        setReservationItemsForward({
          Origin: params.Origin,
          Destination: params.Destination,
          DepartureDate: params.DepartureDate,
          Provider: params.Provider,
          AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
          Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
        });

        setCarInfoIfReturn(responseCars.Cars[currentCar]);
        navigate(
          `/trains/from/${destination}/to/${origin}/date/${dateReturn}`,
          {
            state: {
              cityFrom: cityTo,
              cityTo: cityFrom,
              cityFromCode: destination,
              cityToCode: origin,
              dateTo: dateReturn,
              dateReturn: dateTo,
            },
          }
        );
      } else {
        console.log("bedClothes", isBedClothes);
        setReservationItemsBackward({
          Origin: params.Origin,
          Destination: params.Destination,
          DepartureDate: params.DepartureDate,
          Provider: params.Provider,
          AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
          Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
        });

        navigate("/passengerForm", {
          state: {
            CarParams: params,
            CarInfo: responseCars.Cars[currentCar],
            Compartments: compartmentsStore,
            PlaceReservationType: reservationType,
            totalPrice: totalPrice,
            Discounts: responseCars.Cars[currentCar].Discounts,
            HasElectronicRegistration:
              responseCars.Cars[currentCar].HasElectronicRegistration,
          },
        });
      }
    } else {
      setReservationItemsForward({
        Origin: params.Origin,
        Destination: params.Destination,
        DepartureDate: params.DepartureDate,
        Provider: params.Provider,
        AllowedDocumentTypes: responseCars.AllowedDocumentTypes,
        Car: { ...responseCars.Cars[currentCar], isBedClothes: isBedClothes },
      });
      navigate("/passengerForm", {
        state: {
          CarParams: params,
          CarInfo: responseCars.Cars[currentCar],
          Compartments: compartmentsStore,
          PlaceReservationType: reservationType,
          totalPrice: totalPrice,
        },
      });
    }
  };

  const carTypeName = (CarType) => {
    switch (CarType) {
      case "СВ":
        return "СВ";
      case "ЛЮКС":
        return "Люкс";
      case "КУПЕ":
        return "Купе";
      case "ПЛАЦ":
        return "Плацкарт";
      case "СИД":
        return "Сидячие";
      case "ОБЩ":
        return "Общий";
    }
  };

  useEffect(() => {
    if (isReturnPage) {
      setMinAmountPlaces(placesTo.length - 1);
    } else {
      resetCompartments();
    }

    getCars(params);
  }, []);

  useEffect(() => {
    setCurrentCar(Number(params.CarId));
  }, [params.CarId]);

  useEffect(() => {
    let sum = 0;

    if (
      reservationType === "TwoPlacesAtOnce" ||
      reservationType === "FourPlacesAtOnce"
    ) {
      if (localPagePlaces.length > 0) {
        sum += localPagePlaces[0].MinPrice + localPagePlaces[0].ServiceCost;
      }
    } else {
      localPagePlaces.map((page) => {
        sum += page.MinPrice + page.ServiceCost;
      });
    }

    setTotalPrice(sum.toFixed(2));
  }, [localPagePlaces.length]);

  return (
    <div className="cars-page">
      <ProgressBar />
      <div className="cars-page__selected-cars">
        {isLoaded ? (
          <>
            {isReturnPage ? (
              <TrainInfo
                trainData={trainReturnData}
                currentCar={responseCars.Cars[currentCar]}
              />
            ) : (
              <TrainInfo
                trainData={trainData}
                currentCar={responseCars.Cars[currentCar]}
              />
            )}
            <h1>Выберите вагон и места</h1>
            <Row className="cars-page__selected-cars-carriage">
              <Col className="cars-page__selected-cars-carriage-info">
                <span className="cars-page__selected-cars-carriage-info-number">
                  Вагон {responseCars.Cars[currentCar].CarNumber}
                </span>
                <span>{responseCars.Cars[currentCar].Carrier}</span>
                <span>
                  Тип вагона:{" "}
                  {carTypeName(responseCars.Cars[currentCar].CarTypeName)}
                </span>
                {responseCars.Cars[currentCar].CarDescription ? (
                  <span>
                    Доп. описание:{" "}
                    {responseCars.Cars[currentCar].CarDescription}
                  </span>
                ) : (
                  <></>
                )}
                <span>
                  Класс обслуживания:{" "}
                  {responseCars.Cars[currentCar].ServiceClass}
                </span>
                {responseCars.Cars[currentCar].HasElectronicRegistration ? (
                  <div className="electronic-registration">
                    <span>ЭР</span>
                    <span className="electronic-registration__description">
                      Электронная регистрация
                    </span>
                  </div>
                ) : (
                  <div className="electronic-registration">
                    <span>Нет ЭР</span>
                    <span className="electronic-registration__description">
                      Электронная регистрация
                    </span>
                  </div>
                )}
                <span>
                  Цена: от{" "}
                  {responseCars.Cars[currentCar].PriceRange.MinPrice +
                    responseCars.Cars[currentCar].ServiceCost}
                  ₽
                </span>
                <span>
                  {responseCars.Cars[currentCar].HasPlacesNearBabies
                    ? "В вагоне есть места для пассажиров с детьми"
                    : ""}
                </span>
              </Col>
              <Row className="cars-page__selected-cars-carriage-scheme">
                {responseCars.Cars[currentCar].AvailabilityIndication ===
                "Available" ? (
                  <Outlet context={[responseCars.Cars, state]} />
                ) : (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <b>Вагон недоступен для оформления</b>
                  </div>
                )}
              </Row>
              <Col className="cars-page__selected-cars-carriage-service">
                {responseCars.Cars[currentCar].Services.length > 0 ||
                responseCars.Cars[currentCar].IsBeddingSelectionPossible ||
                responseCars.Cars[currentCar].HasPlacesNearPets ? (
                  <div className="carriage-services">
                    Услуги в вагоне:
                    {responseCars.Cars[currentCar].Services.map((item, i) =>
                      item === "Meal" ? (
                        <div className="services__img" key={i}>
                          <img src={Meal} alt="meal" key={item} />
                          <span className="services__img-description">
                            Питание
                          </span>
                        </div>
                      ) : item === "RestaurantCarOrBuffet" ? (
                        <div className="services__img" key={i}>
                          <img
                            src={RestaurantCarOrBuffet}
                            alt="RestaurantCarOrBuffet"
                            key={item}
                          />
                          <span className="services__img-description">
                            Вагон-ресторан или буфет
                          </span>
                        </div>
                      ) : item === "InfotainmentService" ? (
                        <div className="services__img" key={i}>
                          <img
                            src={InfotainmentService}
                            alt="InfotainmentService"
                            key={item}
                          />
                          <span className="services__img-description">
                            ИРС(Информационно-развлекательный центр)
                          </span>
                        </div>
                      ) : item === "Bedclothes" ? (
                        <div className="services__img" key={i}>
                          <img src={Bedclothes} alt="Bedclothes" key={item} />
                          <span className="services__img-description">
                            Постельное бельё
                          </span>
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                    {responseCars.Cars[currentCar]
                      .IsBeddingSelectionPossible && (
                      <div className="services__img">
                        <img
                          src={IsBeddingSelectionPossible}
                          alt="IsBeddingSelectionPossible"
                        />
                        <span className="services__img-description">
                          Выбор постельного белья
                        </span>
                      </div>
                    )}
                    {responseCars.Cars[currentCar].HasPlacesNearPets && (
                      <div className="services__img">
                        <img src={HasPlacesNearPets} alt="HasPlacesNearPets" />
                        <span className="services__img-description">
                          Возможна перевозка животных
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {responseCars.Cars[currentCar].HasDynamicPricing && (
                  <span>Динамическое ценообразование</span>
                )}
              </Col>
              {responseCars.Cars[currentCar].IsBeddingSelectionPossible ? (
                <Form.Check
                  className="mt-2"
                  type="checkbox"
                  label="Постельное бельё"
                  checked={isBedClothes === true}
                  onChange={() => setIsBedClothes(!isBedClothes)}
                  readOnly
                />
              ) : (
                ""
              )}
            </Row>
            <Row className="cars-page__selected-cars-carriage-list">
              {responseCars.Cars.sort((a, b) =>
                Number(a.CarNumber) > Number(b.CarNumber) ? 1 : -1
              ).map((Car, i) => (
                <Link
                  to={`${i}`}
                  state={state}
                  className="col-2"
                  key={i}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    className={
                      currentCar === i
                        ? "cars-page__selected-cars-carriage-list-item cars-page__selected-cars-carriage-list-item-picked"
                        : "cars-page__selected-cars-carriage-list-item"
                    }
                    onClick={() => setCurrentCar(i)}
                  >
                    Вагон: {Car.CarNumber}
                    <br />
                    Мест: {Car.FreePlaces.length}
                  </div>
                </Link>
              ))}
            </Row>
            <Row className="cars-page__selected-cars-submit">
              <Button
                disabled={localPagePlaces.length <= minAmountPlaces}
                className="cars-page__selected-cars-submit-btn"
                onClick={() => handleSubmit()}
              >
                Продолжить
              </Button>
            </Row>
          </>
        ) : (
          <Container
            style={{ maxHeight: "100%" }}
            className="d-flex justify-content-center mt-5 pb-5"
          >
            <PuffLoader color={"#000"} loading={!isLoaded} size={300} />
          </Container>
        )}
      </div>
    </div>
  );
};
