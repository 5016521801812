import React from "react";
import { Button, Form } from "react-bootstrap";
import { Header } from "../Components/Header/Header";
import { Footer } from "../Components/Footer/Footer";
import { CustomInput } from "../Components/FormComponents/CustomInput";
import "../Styles/Pages/RefundPage.scss";
import { Link } from "react-router-dom";

export const RefundPage = () => {
  return (
    <>
      <Header />
      <div className="refund-page">
        <div className="refund-page__content">
          <h1 className="refund-page__content-header">
            Онлайн возврат билетов
          </h1>
          <Form className="refund-page__content-form">
            <h2 className="refund-page__content-form-header">
              Введите электронную почту, указанную при оформлении билета
            </h2>
            <Form.Group className="refund-page__content-form-input">
              <CustomInput label={"E-mail"} />
            </Form.Group>
            <p className="refund-page__content-form-description">
              На почту придет ссылка, по которой необходимо перейти и потвердить
              возврат билета. Деньги вернутся на карту, с которой был оплачен
              билет, в течение 30 дней.
            </p>
          </Form>
          <Button className="refund-page__content-submit-btn">
            Потвердите возврат билета
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};
