import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/logo.png";
import "../../Styles/Components/Header.scss";
import { baseUrl } from "../../config";
import { useStore } from "effector-react";
import { $localIsStartPage } from "../../Services/trainsService";

export const Header = () => {
  const isStartPage = useStore($localIsStartPage);

  return (
    <header className="header">
      <div
        className={
          isStartPage ? "header__logo header__logo_startpage" : "header__logo"
        }
      >
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
      </div>
      <ul
        className={
          isStartPage
            ? "header__links header__links_startpage"
            : "header__links"
        }
      >
        <li className="header__links-link">
          {/*<Link to={"/refundTickets"}>возврат билетов</Link>*/}
          <a
            href={`${baseUrl}/pay/Railway/return_request`}
            style={{ textDecoration: "none" }}
          >
            возврат билетов
          </a>
        </li>
      </ul>
    </header>
  );
};
