import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { AdultBlock } from "./AdultBlock/AdultBlock";
import { BabyBlock } from "./BabyBlock/BabyBlock";
import "../../Styles/Components/PassengerForm.scss";
import {
  $buyerId,
  $carInfoIfReturnStore,
  $peakedPlacesReturnStore,
  $peakedPlacesToStore,
  $reservationItemsIfReturnStore,
  handleSetCustomers,
  setBuyerId,
} from "../../Services/trainsService";
import { useLocation } from "react-router-dom";
import { useStore } from "effector-react";
import { PassengerSelect } from "./PassengerSelect";
import { validationHandler } from "./Validation";
import { CustomModal } from "../CustomModal/CustomModal";

export const FormContext = createContext("no provider");

export const ReservationForm = ({
  countriesList,
  formId,
  places,
  actualPlace,
  setReservationDto,
  reservationDto,
  isReturnPage,
  isReturnForm,
}) => {
  const customerInitialState = {
    Index: formId,
    DocumentType: "RussianPassport",
    DocumentNumber: "",
    CitizenshipCode: "RU",
    LastName: "",
    FirstName: "",
    MiddleName: "",
    Sex: "",
    Birthday: "",
    isSchoolkid: false,
  };

  const babyCustomerInitialState = {
    Index: places.length + formId,
    DocumentType: "BirthCertificate",
    DocumentNumber: "",
    CitizenshipCode: "RU",
    LastName: "",
    FirstName: "",
    MiddleName: "",
    Sex: "",
    Birthday: "",
    isSchoolkid: false,
  };

  const passengerInitialState = {
    Category: "Adult",
    Phone: "",
    ContactEmailOrPhone: "",
    OrderCustomerIndex: formId,
  };

  const babyPassengerInitialState = {
    Category: "BabyWithoutPlace",
    Phone: "",
    ContactEmailOrPhone: "",
    OrderCustomerIndex: places.length + formId,
  };

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const setSchoolkidHandler = () => {
    if (customer.isSchoolkid === false) {
      setCustomer({
        ...customer,
        ["isSchoolkid"]: true,
      });
      setModalMessage(
        "При посадке на поезд пассажиру необходимо предъявить документы подтверждающие, что он школьник"
      );
      setModal(true);
    } else {
      setCustomer({
        ...customer,
        ["isSchoolkid"]: false,
      });
    }
  };

  const location = useLocation();

  const buyerId = useStore($buyerId);
  const showGender = (place) => {
    let genderType;

    switch (place.Gender) {
      case "Male":
        genderType = "Муж.";
        break;
      case "Female":
        genderType = "Жен.";
        break;
      default:
        genderType = "Смешанный";
        break;
    }

    return genderType;
  };
  //Добавить ребенка или нет
  const [addBaby, setAddBaby] = useState(false);

  //Объект Customer
  const [customer, setCustomer] = useState(() => {
    return customerInitialState;
  });

  //Объект Customer для Ребенка
  const [babyCustomer, setBabyCustomer] = useState(() => {
    return babyCustomerInitialState;
  });

  //Объект ReservationItems

  const [reservationItem, setReservationItem] = useState({
    Index: formId,
    Origin: location.state.CarParams.Origin,
    Destination: location.state.CarParams.Destination,
    DepartureDate: location.state.CarParams.DepartureDate,
    TrainNumber: location.state.CarParams.TrainNumber,
    CarNumber: location.state.CarInfo.CarNumber,
    CarType: location.state.CarInfo.CarType,
    Place: actualPlace.Place.replace(/[A-ZА-Я]/g, ""),
    WithPets: actualPlace.WithPets,
    ServiceClass: actualPlace.ServiceClass,
    InternationalServiceClass: location.state.CarInfo.InternationalServiceClass,
    PlacesRequestType: actualPlace.PlaceReservationType,
    Provider: location.state.CarParams.Provider,
    Passengers: [],
    Gender: actualPlace.Gender,
    isBedClothes: location.state.isBedClothes,
    CarPlaceType: actualPlace.CarPlaceType,
  });

  //Объект Passenger внутри ReservationItems
  const [passengers, setPassengers] = useState(() => {
    return passengerInitialState;
  });

  //Объект Passenger Baby внутри ReservationItems
  const [babyPassengers, setBabyPassengers] = useState(() => {
    return babyPassengerInitialState;
  });

  //Прятать блоки при выборе пассажира из списка

  const [hideBlocks, setHideBlocks] = useState(isReturnForm);

  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(null);

  const reservationItemsIfReturnStore = useStore(
    $reservationItemsIfReturnStore
  );
  const carInfoIfReturnStore = useStore($carInfoIfReturnStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const placesTo = useStore($peakedPlacesToStore);
  const allPlaces = placesTo.concat(placesReturn);

  const reservationItemsIfReturnStoreArr = Object.keys(
    reservationItemsIfReturnStore
  ).map((key) => reservationItemsIfReturnStore[key]);

  const categoryList = [
    {
      key: "Adult",
      name: "Взрослый",
    },
    {
      key: "Child",
      name: "Ребенок, занимающий место",
    },
    {
      key: "BabyWithoutPlace",
      name: "Ребенок без места",
    },
  ];
  useEffect(() => {
    if (!addBaby) {
      setBabyCustomer(babyCustomerInitialState);
      setBabyPassengers(babyPassengerInitialState);
      const temp = reservationDto.Customers;
      const babyIdx = places.length + formId;
      delete temp[babyIdx];

      setReservationDto({
        ...reservationDto,
        Customers: temp,
      });
    }
  }, [addBaby]);

  useEffect(() => {
    if (addBaby) {
      if (isReturnPage) {
        return setReservationDto({
          Customers: {
            ...reservationDto.Customers,
            [formId]: {
              ...customer,
              Birthday: customer.Birthday + "T00:00:00",
            },
            [formId + places.length]: {
              ...babyCustomer,
              Birthday: babyCustomer.Birthday + "T00:00:00",
            },
          },
          ReservationItems: {
            ...reservationDto.ReservationItems,
            [formId]: {
              ...reservationDto.ReservationItems[formId],
              ["Passengers"]: [passengers, babyPassengers],
            },
          },
        });
      }
      setReservationDto({
        Customers: {
          ...reservationDto.Customers,
          [formId]: { ...customer, Birthday: customer.Birthday + "T00:00:00" },
          [formId + places.length]: {
            ...babyCustomer,
            Birthday: babyCustomer.Birthday + "T00:00:00",
          },
        },
        ReservationItems: {
          ...reservationDto.ReservationItems,
          [formId]: {
            ...reservationItem,
            ["Passengers"]: [passengers, babyPassengers],
          },
        },
      });
    } else {
      if (isReturnPage) {
        return setReservationDto({
          Customers: {
            ...reservationDto.Customers,
            [formId]: {
              ...customer,
              Birthday: customer.Birthday + "T00:00:00",
            },
          },
          ReservationItems: {
            ...reservationDto.ReservationItems,
            [formId]: {
              ...reservationDto.ReservationItems[formId],
              ["Passengers"]: [passengers],
            },
          },
        });
      }
      setReservationDto({
        Customers: {
          ...reservationDto.Customers,
          [formId]: { ...customer, Birthday: customer.Birthday + "T00:00:00" },
        },
        ReservationItems: {
          ...reservationDto.ReservationItems,
          [formId]: {
            ...reservationItem,
            ["Passengers"]: [passengers],
          },
        },
      });
    }
  }, [customer, babyCustomer, passengers, babyPassengers]);

  //Подсчет возраста
  const ageCalculate = (minAge) => {
    let todayDate = new Date();
    const valueOfAge = todayDate.setTime(
      todayDate.valueOf() - minAge * 365 * 24 * 60 * 60 * 1000
    );
    return valueOfAge;
  };

  const ageCalculateDate = (minAge) => {
    let todayDate = new Date();
    todayDate.setTime(todayDate.valueOf() - minAge * 365 * 24 * 60 * 60 * 1000);

    return todayDate.toISOString().split("T")[0];
  };
  //Валидация
  const [validation, setValidation] = useState({
    DocumentType: { empty: true, error: false, message: "" },
    BabyDocumentType: { empty: false, error: false, message: "" },
    DocumentNumber: { empty: true, error: false, message: "" },
    LastName: { empty: true, error: false, message: "" },
    FirstName: { empty: true, error: false, message: "" },
    MiddleName: { empty: true, error: false, message: "" },
    Sex: { empty: true, error: false, message: "" },
    Phone: { empty: true, error: false, message: "" },
    BabyPhone: { empty: true, error: false, message: "" },
    Birthday: { empty: true, error: false, message: "" },
    ContactEmailOrPhone: { empty: true, error: false, message: "" },
    BabyContactEmailOrPhone: { empty: true, error: false, message: "" },
    BabyLastName: { empty: false, error: false, message: "" },
    BabyFirstName: { empty: false, error: false, message: "" },
    BabyMiddleName: { empty: false, error: false, message: "" },
    BabyDocumentNumber: { error: false, message: "" },
    BabyBirthday: { empty: false, error: false, message: "" },
    BabySex: { empty: false, error: false, message: "" },
  });

  //Ввод данных в Customer
  const setCustomerHandler = (e) => {
    const { name, value, id } = e.target;
    const splittedName = name.split("-")[0];
    validationHandler(
      id,
      splittedName,
      value,
      validation,
      setValidation,
      customer,
      babyCustomer,
      passengers,
      ageCalculate,
      actualPlace.CarPlaceType
    );

    if (id === "Adult") {
      setCustomer({
        ...customer,
        [splittedName]: value,
      });
    } else {
      if (splittedName === "BabySex") {
        return setBabyCustomer({
          ...babyCustomer,
          Sex: value,
        });
      }
      if (id === "Child") {
        setCustomer({
          ...customer,
          [splittedName]: value,
        });
      }
      setBabyCustomer({
        ...babyCustomer,
        [splittedName]: value,
      });
    }
  };

  //Ввод данных в Passenger
  const setPassengersHandler = (e) => {
    const { name, value, id } = e.target;
    const splittedName = name.split("-")[0];
    validationHandler(
      id,
      splittedName,
      value,
      validation,
      setValidation,
      customer,
      babyCustomer,
      passengers,
      ageCalculate
    );

    if (id === "Adult") {
      setPassengers({
        ...passengers,
        [splittedName]: value,
      });
    } else {
      setBabyPassengers({
        ...babyPassengers,
        [splittedName]: value,
      });
    }
  };

  const setCitizenshipCode = (value, id) => {
    id === "Adult"
      ? setCustomer({
          ...customer,
          ["CitizenshipCode"]: value,
        })
      : setBabyCustomer({
          ...babyCustomer,
          ["CitizenshipCode"]: value,
        });
  };

  const passengerSelectHandler = (e) => {
    if (e.target.value !== "newPassenger") {
      setHideBlocks(true);
      setSelectedPassengerIndex(e.target.value);
      const noExtraCustomers = Object.fromEntries(
        Object.entries(reservationDto.Customers)
          .filter(([key, value]) => key !== String(formId))
          .filter(([key, value]) => key !== String(places.length + formId))
      );

      setReservationDto({
        Customers: noExtraCustomers,
        ReservationItems: {
          ...reservationDto.ReservationItems,
          [formId]: {
            ...reservationItem,
            Passengers: [
              reservationDto.ReservationItems[e.target.value].Passengers[0],
            ],
            isBedClothes:
              reservationDto.ReservationItems[e.target.value].isBedClothes,
            CarPlaceType:
              reservationDto.ReservationItems[e.target.value].CarPlaceType,
          },
        },
      });
    } else {
      setHideBlocks(false);
      setSelectedPassengerIndex(null);
    }
  };

  useEffect(() => {
    if (actualPlace.CarPlaceType === "MotherAndBaby") {
      setAddBaby(true);
    }
  }, []);

  return (
    <div className="passenger-form">
      <CustomModal
        open={modal}
        onOpen={setModal}
        onClose={() => setModal(false)}
      >
        <h2 style={{ color: "red" }}>Внимание!</h2>
        <h4>{modalMessage}</h4>
      </CustomModal>
      <Row>
        <Col sm={12} lg={6}>
          {isReturnPage === true ? (
            <>
              {
                categoryList.find((item) => item.key === passengers.Category)
                  ?.name
              }
              <br />
              <span>
                {actualPlace.Place} место - {actualPlace.CarPlaceTypeMeaning},{" "}
                {showGender(actualPlace)}{" "}
                {isReturnForm
                  ? location.state.CarInfo.CarTypeName
                  : carInfoIfReturnStore.CarTypeName}
                , Поезд № {reservationItemsIfReturnStoreArr[formId].TrainNumber}
              </span>
              <br />
            </>
          ) : (
            <span>
              {
                categoryList.find((item) => item.key === passengers.Category)
                  ?.name
              }
              , {actualPlace.Place} место - {actualPlace.CarPlaceTypeMeaning},{" "}
              {showGender(actualPlace)} {location.state.CarInfo.CarTypeName}
            </span>
          )}
        </Col>
      </Row>
      <FormContext.Provider
        value={{
          setCustomerHandler,
          setCitizenshipCode,
          setPassengersHandler,
          ageCalculateDate,
          setValidation,
          setAddBaby,
          formId: formId,
          babyId: places + formId,
          validation: validation,
          customer: customer,
          babyCustomer: babyCustomer,
          passengers: passengers,
          countriesList: countriesList,
          hideBlocks: hideBlocks,
          selectedPassengerIndex: selectedPassengerIndex,
          reservationDto: reservationDto,
          isReturnForm: isReturnForm,
          actualPlace: actualPlace,
        }}
      >
        <AdultBlock
          setSchoolKid={() => setSchoolkidHandler()}
          hideBlocks={hideBlocks}
          type="Adult"
        >
          {places.length > 1 ? (
            <Form.Group as={Col} sm={12} lg={4}>
              <Form.Check
                className="mt-3"
                type="checkbox"
                label="Этот пассажир — покупатель"
                checked={buyerId === formId}
                onChange={() => setBuyerId(formId)}
                readOnly
              />
            </Form.Group>
          ) : (
            ""
          )}
        </AdultBlock>

        {addBaby ? hideBlocks ? "" : <BabyBlock type="Baby" /> : <></>}
      </FormContext.Provider>
    </div>
  );
};
