import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { FormElement } from './FormElement';
import axios from 'axios';
import { baseUrl } from '../../config';
import {
  $peakedPlacesReturnStore,
  $peakedPlacesToStore,
  $reservationItemsForwardBackwardStore,
  setErrorMessage,
} from '../../Services/trainsService';

import { prepareInfoForReservation } from '../../Services/createReservationService';
import '../../Styles/Pages/PassengerFormPage.scss';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { CustomModal } from '../CustomModal/CustomModal';

const FormComponent = ({ preformInfo, totalPrice, placeReservationType }) => {
  const carsInfo = useStore($reservationItemsForwardBackwardStore);
  const placesTo = useStore($peakedPlacesToStore);
  const placesReturn = useStore($peakedPlacesReturnStore);
  const navigate = useNavigate();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    patronymic: '',
    birthDate: '',
    gender: '',
    documentType: '',
    documentNumber: '',
    mobilePhone: '',
    email: '',
  });

  const [countriesList, setCountries] = useState([]);

  const [reservationDto, setReservationDto] = useState({
    Customers: {},
    Passengers: {},
    Buyer: 0,
  });

  const [validationDocumentObjects, setValidationDocumentObject] = useState({});

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const checkAllDocumentsIsValid = () => {
    const documentsArray = Object.keys(validationDocumentObjects).map(key => validationDocumentObjects[key]);

    return documentsArray.some(item => item.IsValid === false);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value.trim() });
  };

  const isChild = formData.category === 'child';
  const isBaby = formData.category === 'baby';
  const maxBirthDate = isChild ? 10 : isBaby ? 5 : null;

  const handleBirthDateChange = event => {
    // Проверка на ограничение календаря по возрасту
    const { name, value } = event.target;
    // Выполните валидацию значения в поле даты здесь, если необходимо
    setFormData({ ...formData, [name]: value });
  };

  const isValidDocumentNumber = value => {
    // Пример проверки серии и номера документа: AB123456
    const documentNumberRegex = /^[A-Z]{2}\d{6}$/;
    return documentNumberRegex.test(value);
  };

  const isValidMobilePhone = value => {
    // Пример проверки мобильного телефона: +1 123-456-7890
    const mobilePhoneRegex = /^\+\d{1,3}\s\d{3}-\d{3}-\d{4}$/;
    return mobilePhoneRegex.test(value);
  };

  const isValidEmail = value => {
    // Пример проверки электронной почты
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // Выполните дополнительную валидацию, если необходимо, перед отправкой данных на сервер
    // Например, можно проверить обязательные поля или другие правила
    console.log(formData);
  };

  function calculateMaxBirthDate(maxBirthDate) {
    return undefined;
  }

  //ФУНКЦИЯ ДЛЯ ПОЛУЧЕНИЯ СПИСКА СТРАН
  const getCountries = () => {
    try {
      axios.get(`${baseUrl}/api/info/get_countries`).then(response => {
        setCountries(response.data);
      });
    } catch (error) {
      console.log('Ошибка при получении списка стран', error);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const makeQueryAndGoToNextPage = () => {
    const res = prepareInfoForReservation(reservationDto, placesTo, placesReturn, carsInfo);

    const json = JSON.stringify(res);

    try {
      setIsLoadingSubmit(true);
      axios
        .post(`${baseUrl}/api/Railway/create_reservation`, json, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(response => {
          setIsLoadingSubmit(false);
          navigate('/reservationPage', {
            state: {
              responseReservation: response.data,
            },
          });
        })
        .catch(error => {
          setIsLoadingSubmit(false);
          setErrorMessage(error);
        });
    } catch (error) {
      throw error;
    }
  };

  const onCloseModal = async () => {
    if (placeReservationType === 'FourPlacesAtOnce' || placeReservationType === 'TwoPlacesAtOnce') {
      setModal(false);
    } else {
      await setModal(false);
      makeQueryAndGoToNextPage();
    }
  };

  function onSubmitHandler(event) {
    event.preventDefault();

    const adultAmountCheck = () => {
      const passengersArray = Object.entries(reservationDto.Passengers).map(([key, value]) => value);
      const checkedPassengersArray = passengersArray.filter(item => item.Category === 'Adult');

      return checkedPassengersArray.length;
    };

    const adultAmount = adultAmountCheck();

    if (adultAmount === 0) {
      setModalMessage('Проезд детей до 10 лет без сопровождения взрослого не допускается');
      setModal(true);
    } else {
      makeQueryAndGoToNextPage();
    }
  }

  return (
    <>
      <h2>Пассажиры</h2>

      <div className={isLoadingSubmit ? 'overlayActive' : 'overlayNone'}>
        <Container
          style={{ maxHeight: '100%', minHeight: '100vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          <PuffLoader color={'#000000'} loading={isLoadingSubmit} size={300} />
        </Container>
      </div>
      <CustomModal open={modal} onOpen={setModal} onClose={() => onCloseModal()}>
        <h2 style={{ color: 'red' }}>Внимание!</h2>
        <h4>{modalMessage}</h4>
      </CustomModal>
      <Form onSubmit={onSubmitHandler}>
        {preformInfo.map((formElement, i) => {
          let discounts = { forward: null, backward: null };

          if (formElement.forward === null) {
            discounts.forward = placesTo[0].Discounts;

            if (placesReturn.length !== 0) {
              discounts.backward = placesReturn[0].Discounts;
            }
          } else {
            placesTo.forEach((item, index) => {
              if (item.Place === formElement.forward) {
                discounts.forward = item.Discounts;
              }
            });
            if (placesReturn.length !== 0) {
              placesReturn.forEach((item, index) => {
                if (item.Place === formElement.backward) {
                  discounts.backward = item.Discounts;
                }
              });
            }
          }
          return (
            <FormElement
              validationDocumentObjects={validationDocumentObjects}
              setValidationDocumentObjects={setValidationDocumentObject}
              preformInfo={preformInfo}
              discounts={discounts}
              countriesList={countriesList}
              reservationDto={reservationDto}
              setReservationDto={setReservationDto}
              formElement={formElement}
              amountOfElements={preformInfo.length}
              formId={i}
              carsInfo={carsInfo}
              key={i}
            />
          );
        })}

        {/*<Col className="d-flex justify-content-end">*/}
        {/*    <Col className="passenger-form-page__content-book-tickets">*/}
        {/*        <>*/}
        {/*            <p>Билетов: {preformInfo.length}</p>*/}
        {/*            <p>{totalPrice}₽</p>*/}
        {/*        </>*/}
        {/*    </Col>*/}
        {/*</Col>*/}
        <Col className="d-flex justify-content-end">
          <Button
            className={'passenger-form-page__content-book-btn'}
            disabled={isLoadingSubmit || checkAllDocumentsIsValid()}
            type="submit"
          >
            Забронировать
          </Button>
        </Col>
      </Form>
    </>
  );
};

export default FormComponent;
