import { createStore, createEvent } from "effector";
import connectLocalStorage from "effector-localstorage/sync";
import { useStore } from "effector-react";

//Локальный стор для хранения мест на странице CarPage

export const setLocalPlace = createEvent();

export const $localCarPagePlaceStore = createStore([]);

$localCarPagePlaceStore.on(setLocalPlace, (state, data) => {
  state = data;
  return state;
});

//Индикатор стартовой страницы

export const setIsStartPage = createEvent();

export const $localIsStartPage = createStore("");

$localIsStartPage.on(setIsStartPage, (state, data) => {
  state = data;
  return state;
});

//Сохранеие информации о выбранном поезде

export const setLocalTrainData = createEvent();

const trainInfoFromStoreLocalStorage = connectLocalStorage("trainInfo").onError(
  (err) => console.log(err)
);

export const $localTrainInfoFromStore = createStore(
  trainInfoFromStoreLocalStorage.init("")
);

$localTrainInfoFromStore.on(setLocalTrainData, (state, data) => {
  state = data;
  return state;
});

$localTrainInfoFromStore.watch(trainInfoFromStoreLocalStorage);

//Сохранеие информации о выбранном поезде - ОБРАТНО

export const setLocalTrainDataReturn = createEvent();
export const resetLocalTrainDataReturn = createEvent();

const trainInfoReturnFromStoreLocalStorage = connectLocalStorage(
  "trainReturnInfo"
).onError((err) => console.log(err));

export const $localTrainInfoReturnFromStore = createStore(
  trainInfoReturnFromStoreLocalStorage.init("")
);

$localTrainInfoReturnFromStore
  .on(setLocalTrainDataReturn, (state, data) => {
    state = data;
    return state;
  })
  .on(resetLocalTrainDataReturn, () => "");

$localTrainInfoReturnFromStore.watch(trainInfoReturnFromStoreLocalStorage);

//Сохранение даты - Обратно

export const dateReturnStoreSetter = createEvent();

const dateReturnStoreLocalStorage = connectLocalStorage("dateReturn").onError(
  (err) => console.log(err)
);
export const $dateReturnStore = createStore(
  dateReturnStoreLocalStorage.init("")
);

$dateReturnStore.on(dateReturnStoreSetter, (state, data) => {
  state = data;
  return state;
});

$dateReturnStore.watch(dateReturnStoreLocalStorage);

//Сохранение мест - Туда
export const peakPlaceTo = createEvent();

export const resetPlaces = createEvent();

const placesToLocalStorage = connectLocalStorage("placesTo").onError((err) =>
  console.log(err)
);

export const $peakedPlacesToStore = createStore(placesToLocalStorage.init([]));

const updatePeakedPlacesStore = (state, data) => {
  state = data;
  return state;
};

$peakedPlacesToStore
  .on(peakPlaceTo, updatePeakedPlacesStore)
  .on(resetPlaces, () => []);
$peakedPlacesToStore.watch(placesToLocalStorage);

//Сохранение мест - Обратно
export const peakPlaceReturn = createEvent();

const placesReturnLocalStorage = connectLocalStorage("placesReturn").onError(
  (err) => console.log(err)
);

export const $peakedPlacesReturnStore = createStore(
  placesReturnLocalStorage.init([])
);

$peakedPlacesReturnStore
  .on(peakPlaceReturn, updatePeakedPlacesStore)
  .on(resetPlaces, () => []);

$peakedPlacesReturnStore.watch(placesReturnLocalStorage);

//Индикатор страницы ОБРАТНО

export const setIsReturnPage = createEvent();
export const resetIsReturnPage = createEvent();

const isReturnPageLocalStorage = connectLocalStorage("isReturnPage").onError(
  (err) => console.log(err)
);

export const $isReturnPageStore = createStore(
  isReturnPageLocalStorage.init(false)
)
  .on(setIsReturnPage, (state, data) => {
    state = data;
    return state;
  })
  .on(resetIsReturnPage, () => false);

$isReturnPageStore.watch(isReturnPageLocalStorage);

//Buyer - id покупателя

export const setBuyerId = createEvent();
export const $buyerId = createStore(0).on(setBuyerId, (state, data) => {
  state = data;
  return state;
});

//Обнулении параметров запроса при ошибке

export const resetAllReservationParams = createEvent();

//Customers

const setCustomers = createEvent();

export const resetCustomers = createEvent();
export const $customersStore = createStore({})
  .on(setCustomers, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetCustomers, (state) => {
    state = {};
    return state;
  })
  .on(resetAllReservationParams, (state) => {
    state = {};
    return state;
  });

export const handleSetCustomers = setCustomers.prepend(({ id, value }) => ({
  id: id,
  value: value,
}));

//Customers для малыша без места
const setBabyCustomers = createEvent();
export const $babyCustomersStore = createStore({})
  .on(setBabyCustomers, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetCustomers, (state) => {
    state = {};
    return state;
  })
  .on(resetAllReservationParams, (state) => {
    state = {};
    return state;
  });

export const handleSetBabyCustomers = setBabyCustomers.prepend(
  ({ id, value }) => ({
    id: id,
    value: value,
  })
);

//Passengers для малыша без места
const setBabyPassengers = createEvent();
const resetBabyPassengers = createEvent();
export const $babyPassengersStore = createStore({})
  .on(setBabyPassengers, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetBabyPassengers, (state) => {
    state = {};
    return state;
  })
  .on(resetAllReservationParams, (state) => {
    state = {};
    return state;
  });

export const handleSetBabyPassengers = setBabyPassengers.prepend(
  ({ id, value }) => ({
    id: id,
    value: value,
  })
);

//ReservationItems
export const resetReservationItems = createEvent();

const setReservationItems = createEvent();
export const $reservationItemsStore = createStore({})
  .on(setReservationItems, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetReservationItems, (state) => {
    state = {};
    return state;
  })
  .on(resetAllReservationParams, (state) => {
    state = {};
    return state;
  });

export const handleSetReservationItems = setReservationItems.prepend(
  ({ id, value }) => ({
    id: id,
    value: value,
  })
);

//Passengers в случае когда выбрана дата ОБРАТНО
const setPassengersIfReturn = createEvent();
export const $passengersIfReturnStore = createStore({})
  .on(setPassengersIfReturn, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetAllReservationParams, (state) => {
    state = {};
    return state;
  });

export const handleSetPassengersIfReturn = setPassengersIfReturn.prepend(
  ({ id, value }) => ({
    id: id,
    value: value,
  })
);

//ReservationItems в случае когда выбрана дата ОБРАТНО

export const setReservationItemsIfReturn = createEvent();

const reservationItemsIfReturnLocalStorage = connectLocalStorage(
  "reservationItemsIfReturn"
).onError((err) => console.log(err));
export const $reservationItemsIfReturnStore = createStore(
  reservationItemsIfReturnLocalStorage.init({})
);

$reservationItemsIfReturnStore
  .on(setReservationItemsIfReturn, (s, { id, value }) => ({
    ...s,
    [id]: value,
  }))
  .on(resetReservationItems, (state) => {
    state = {};
    return state;
  });

$reservationItemsIfReturnStore.watch(reservationItemsIfReturnLocalStorage);

//ReservationItemsForwardBackward

export const setReservationItemsForward = createEvent();
export const setReservationItemsBackward = createEvent();

const reservationItemsForwardBackwardLocalStorage = connectLocalStorage(
  "reservationItemsForwardBackward"
).onError((err) => console.log(err));
export const $reservationItemsForwardBackwardStore = createStore(
  reservationItemsForwardBackwardLocalStorage.init({
    forward: null,
    backward: null,
  })
);

$reservationItemsForwardBackwardStore
  .on(setReservationItemsForward, (s, value) => ({
    ...s,
    forward: value,
  }))
  .on(setReservationItemsBackward, (s, value) => ({
    ...s,
    backward: value,
  }))
  .on(resetReservationItems, (state) => {
    state = {
      forward: null,
      backward: null,
    };
    return state;
  });

$reservationItemsForwardBackwardStore.watch(
  reservationItemsForwardBackwardLocalStorage
);

//CarInfoIfReturn - информация о вагоне при выборе обратной даты
export const setCarInfoIfReturn = createEvent();

export const resetCarInfoIfReturn = createEvent();

const carInfoIfReturnLocalStorage = connectLocalStorage(
  "carInfoIfReturn"
).onError((err) => console.log(err));

export const $carInfoIfReturnStore = createStore(
  carInfoIfReturnLocalStorage.init({})
);

$carInfoIfReturnStore
  .on(setCarInfoIfReturn, (state, data) => {
    state = data;
    return state;
  })
  .on(resetCarInfoIfReturn, (state) => {
    state = {};
    return state;
  });

$carInfoIfReturnStore.watch(carInfoIfReturnLocalStorage);

//Открытие модального окна при серверной ошибке

export const setModalError = createEvent();
export const $modalErrorTogglerStore = createStore(false);

$modalErrorTogglerStore.on(setModalError, (state, data) => {
  state = data;
  return state;
});

//Сохранение сообщение об серверной ошибке

export const setErrorMessage = createEvent();

export const $errorMessageStore = createStore(null).on(
  setErrorMessage,
  (state, data) => {
    state = data;
    return state;
  }
);

//ФУНКЦИЯ ОБРАБОТКИ СЕРВЕРНЫХ ОШИБОК

export const errorHandling = (error) => {
  if (error.response.status) {
    switch (error.response.status) {
      case 500:
        setErrorMessage(error.response.data.detail);
        setModalError(true);
        break;
      case 422:
        setErrorMessage({
          Code: "422",
          Message: error.response.data.detail,
        });
        setModalError(true);
        break;

      case 504:
        setErrorMessage({
          Code: "504 Gateway Time Out",
          Message: [
            {
              Code: 504,
              Message:
                "Сервер, действуя как шлюз или прокси, не может получить ответ вовремя.",
            },
          ],
        });
        setModalError(true);
        break;
      default:
        setErrorMessage({
          Code: "Unknown",
          Message: "Сервис временно недоступен",
        });
        break;
    }
  } else {
    setErrorMessage({
      Code: error.code,
      Message: "Сервис временно недоступен",
    });
    setModalError(true);
  }
};

//Валидация нескольких форм
export const setValidationForm = createEvent();
export const resetValidationForm = createEvent();
export const $multipleValidationStore = createStore({})
  .on(setValidationForm, (s, { id, value }) => ({
    ...s,
    [id]: {
      error: value,
    },
  }))
  .on(resetValidationForm, (s) => {
    s = {};
    return s;
  });

//Compartments для create_reservation
export const setCompartments = createEvent();
export const resetCompartments = createEvent();
export const $compartmentsForReservation = createStore({})
  .on(setCompartments, (s, data) => ({
    ...s,
    ...data,
  }))
  .on(resetCompartments, (s) => {
    s = {};
    return s;
  });

//PlaceReservationType

export const setPlaceReservation = createEvent();
export const $placeReservationTypeStore = createStore("").on(
  setPlaceReservation,
  (s, data) => {
    s = data;
    return s;
  }
);

// export const setReservationCustomers = createEvent();
//
// export const $reservationCustomers = createStore([]).on(
//   setReservationCustomers
// );
