import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import "../../Styles/Components/RailwayForm.scss";
import { LiveSearch } from "../LiveSearch/LiveSearch";
import { CustomDatepicker } from "../CustomDatepicker/CustomDatepicker";
import { DateObject } from "react-multi-date-picker";
import { useNavigate } from "react-router-dom";
import datePickerIcon from "../../Assets/startPage/datePeakerMobile.svg";
import payment from "./../../Assets/startPage/payment.png";
import swap from "../../Assets/startPage/swap.svg";
import { baseUrl } from "../../config";
import moment from "moment-timezone";
import { dateReturnStoreSetter } from "../../Services/trainsService";
export const RailwayForm = ({
  cityFrom = "",
  cityTo = "",
  cityFromCode = 0,
  cityToCode = 0,
  dateToPlaceholder = "",
  dateReturnPlaceholder = "",
  dateReturnCode = "",
  dateToCode = "",
  isReturnPage,
}) => {
  const [selectFrom, setSelectFrom] = useState(cityFromCode); //Информация о городе(код) (откуда)
  const [selectTo, setSelectTo] = useState(cityToCode); //Информация о городе(код) (куда)
  const [valueFrom, setValueFrom] = useState(cityFrom); //Название города  (откуда)
  const [valueTo, setValueTo] = useState(cityTo); //Название города  (куда)
  const [displayDateTo, setDisplayDateTo] = useState(dateToPlaceholder); //Дата оторбражаемая в инпуте
  const [displayDateReturn, setDisplayDateReturn] = useState(
    dateReturnPlaceholder
  ); //Дата оторбражаемая в инпуте
  const [dateTo, setDateTo] = useState(dateToCode); //Дата туда
  const [dateReturn, setDateReturn] = useState(dateReturnCode); //Дата обратно
  const [responseDataTo, setResponseDataTo] = useState([]); //Значение информации при запросе
  const [responseDataFrom, setResponseDataFrom] = useState([]); //Значение информации при запросе
  const [inputReturnCheckbox, setInputReturnCheckbox] = useState(true);
  const [swapedCities, setSwapedCities] = useState(false);
  const navigate = useNavigate();
  var moment = require("moment-timezone");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isReturnPage) {
      navigate(`/trains/from/${selectFrom}/to/${selectTo}/date/${dateTo}`, {
        state: {
          cityFrom: valueFrom,
          cityTo: valueTo,
          cityFromCode: selectFrom,
          cityToCode: selectTo,
          dateTo: dateTo,
          dateReturn: dateTo,
        },
      });
    } else {
      navigate(`/trains/from/${selectFrom}/to/${selectTo}/date/${dateTo}`, {
        state: {
          cityFrom: valueFrom,
          cityTo: valueTo,
          cityFromCode: selectFrom,
          cityToCode: selectTo,
          dateTo: dateTo,
          dateReturn: dateReturn,
          inputReturnCheckboxState: inputReturnCheckbox,
        },
      });
    }
  };

  //ФУНКЦИЯ ЗАПРОСА ДЛЯ ПОИСКА ГОРОДОВ
  const getCitiesFrom = (value) => {
    try {
      axios
        .get(`${baseUrl}/api/info/get_cities?`, {
          params: {
            city_name: value,
          },
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then((response) => {
          setResponseDataFrom(response.data);
        });
    } catch (error) {
      console.log("Ошибка при получении списка городов", error);
    }
  };

  const getCitiesTo = (value) => {
    try {
      axios
        .get(`${baseUrl}/api/info/get_cities?`, {
          params: {
            city_name: value,
          },
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        })
        .then((response) => {
          setResponseDataTo(response.data);
        });
    } catch (error) {
      console.log("Ошибка при получении списка городов", error);
    }
  };

  //Функция выбора дефолтных городов
  const setDefaultCity = (city, direction) => {
    if (city === "Москва") {
      if (direction === "Откуда") {
        setValueFrom(city);
        setSelectFrom(2000000);
      } else {
        setValueTo(city);
        setSelectTo(2000000);
      }
    } else {
      if (direction === "Откуда") {
        setValueFrom(city);
        setSelectFrom(2004000);
      } else {
        setValueTo(city);
        setSelectTo(2004000);
      }
    }
  };

  //Функция выбора даты дефолтных дней(Завтра/Послезавтра)
  const setDefaultDate = (day, direction) => {
    let today = new Date();
    let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    let afterTomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000);

    const timeTz = moment.tz.guess();

    const time = moment().tz(timeTz).format("THH:mm:ss");

    const setDay = (day) => {
      switch (day) {
        case "завтра":
          return new DateObject(tomorrow);
        case "послезавтра":
          return new DateObject(afterTomorrow);
        default:
          return new DateObject(today);
      }
    };

    if (direction === "туда") {
      setDateTo(setDay(day).format("YYYY-MM-DD") + time);
      setDisplayDateTo(setDay(day).format("DD/MM/YYYY"));
    } else {
      setDateReturn(setDay(day).format("YYYY-MM-DD") + time);
      setDisplayDateReturn(setDay(day).format("DD/MM/YYYY"));
    }
  };

  //Поменять города местами
  const swapCities = () => {
    if (selectTo === 0 || selectFrom === 0) return;
    let temp = valueFrom;
    let temp2 = selectFrom;
    setSelectFrom(selectTo);
    setSelectTo(temp2);
    setValueFrom(valueTo);
    setValueTo(temp);
    setSwapedCities(true);
  };

  return (
    <div className="ticket-form">
      <div className="ticket-form__mobile-title ticket-form__container">
        <h2>Билеты на поезд</h2>
      </div>
      <Form
        className={
          isReturnPage
            ? "ticket-form__elements ticket-form__container ticket-form__return-page"
            : "ticket-form__elements ticket-form__container"
        }
        onSubmit={handleSubmit}
      >
        <Form.Group className="ticket-form__elements-city-selection">
          <Form.Group className="ticket-form__elements-city-selection-from">
            <Form.Label>Откуда</Form.Label>
            <LiveSearch
              type={"Cities"}
              swaped={swapedCities}
              setSwaped={setSwapedCities}
              placeholder={cityFrom ? cityFrom : "Откуда"}
              labelKey={"NameRu"}
              valueKey={"ExpressCode"}
              value={valueFrom}
              onChange={setValueFrom}
              onSelect={setSelectFrom}
              onSearch={getCitiesFrom}
              responseData={responseDataFrom}
            />

            <span
              onClick={() => setDefaultCity("Санкт-Петербург", "Откуда")}
              className="ticket-form__default-cities"
            >
              Санкт-Петербург{" "}
            </span>
            <span
              onClick={() => setDefaultCity("Москва", "Откуда")}
              className="ticket-form__default-cities"
            >
              Москва
            </span>
          </Form.Group>
          <div className="ticket-form__elements-city-selection-swap-city-btn">
            <img src={swap} alt="swap" onClick={swapCities} />
          </div>
          <Form.Group className="ticket-form__elements-city-selection-to">
            <Form.Label>Куда</Form.Label>
            <LiveSearch
              type={"Cities"}
              swaped={swapedCities}
              setSwaped={setSwapedCities}
              placeholder={cityTo ? cityTo : "Куда"}
              labelKey={"NameRu"}
              valueKey={"ExpressCode"}
              value={valueTo}
              onChange={setValueTo}
              onSelect={setSelectTo}
              onSearch={getCitiesTo}
              responseData={responseDataTo}
            />
            <span
              onClick={() => setDefaultCity("Москва", "Куда")}
              className="ticket-form__default-cities"
            >
              Москва{" "}
            </span>
            <span
              onClick={() => setDefaultCity("Санкт-Петербург", "Куда")}
              className="ticket-form__default-cities"
            >
              Санкт-Петербург
            </span>
          </Form.Group>
        </Form.Group>
        <Form.Group className="ticket-form__elements-date-selection">
          <Form.Group className="ticket-form__elements-date-selection-departure-date">
            <Form.Label>Дата</Form.Label>
            <CustomDatepicker
              placeholder={dateToPlaceholder ? dateToPlaceholder : "Туда"}
              editable={false}
              minDate={new Date()}
              displayFormat={"DD/MM/YYYY"}
              apiFormat={"YYYY-MM-DD"}
              onSelectDate={setDateTo}
              displayValue={displayDateTo}
              onChange={setDisplayDateTo}
            />
            <span
              onClick={() => setDefaultDate("сегодня", "туда")}
              className="ticket-form__default-cities"
            >
              сегодня{" "}
            </span>
            <span
              onClick={() => setDefaultDate("завтра", "туда")}
              className="ticket-form__default-cities"
            >
              завтра{" "}
            </span>
            <span
              onClick={() => setDefaultDate("послезавтра", "туда")}
              className="ticket-form__default-cities"
            >
              послезавтра
            </span>
          </Form.Group>
          <div className="ticket-form__elements-date-selection-date-img">
            <img src={datePickerIcon} alt={"calendar"} />
          </div>
          <Form.Group
            className={
              !inputReturnCheckbox && dateTo
                ? "ticket-form__elements-date-selection-date-return"
                : "ticket-form__elements-date-selection-date-return ticket-form__elements-date-selection-date-return-disabled"
            }
          >
            <div className="ticket-form__elements-date-selection-date-return-label">
              <Form.Check
                onChange={() => setInputReturnCheckbox(!inputReturnCheckbox)}
              />
              <Form.Label>Обратно</Form.Label>
            </div>
            <CustomDatepicker
              disabled={inputReturnCheckbox || !dateTo}
              placeholder={
                dateReturnPlaceholder ? dateReturnPlaceholder : "Обратно"
              }
              editable={false}
              minDate={new Date(dateTo.split("T")[0])}
              displayFormat={"DD/MM/YYYY"}
              apiFormat={"YYYY-MM-DD"}
              onSelectDate={setDateReturn}
              displayValue={displayDateReturn}
              onChange={setDisplayDateReturn}
            />
            <span
              onClick={
                !inputReturnCheckbox && dateTo
                  ? () => setDefaultDate("завтра", "обратно")
                  : () => ""
              }
              className="ticket-form__default-cities"
            >
              завтра{" "}
            </span>
            <span
              disabled={inputReturnCheckbox || !dateTo}
              onClick={
                !inputReturnCheckbox && dateTo
                  ? () => setDefaultDate("послезавтра", "обратно")
                  : () => ""
              }
              className="ticket-form__default-cities"
            >
              послезавтра
            </span>
          </Form.Group>
        </Form.Group>
        <Form.Group className="ticket-form__elements-submit-btn">
          <button
            className={"button"}
            disabled={valueFrom === "" || valueTo === "" || dateTo === ""}
            type="submit"
          >
            найти билеты
          </button>
        </Form.Group>
        <div className="ticket-form__elements-payment">
          <p className="ticket-form__elements-payment-description">
            Безопасная оплата
          </p>
          <div className="ticket-form__elements-payment-img">
            <img src={payment} alt="payments" />
          </div>
        </div>
      </Form>
      <span className="ticket-form__scrolldown">
        <svg
          width="44"
          height="23"
          viewBox="0 0 44 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 2L21.802 19L42 2" stroke="#FF5C00" strokeWidth="5" />
        </svg>
      </span>
    </div>
  );
};
