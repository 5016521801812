import React from "react";
import DatePicker from "react-multi-date-picker";
import "../../Styles/Components/CustomDatepicker.scss";
import moment from "moment-timezone";

export const CustomDatepicker = ({
  editable,
  minDate,
  displayFormat,
  disabled,
  apiFormat, //формат даты для отправки запроса
  onSelectDate,
  placeholder,
  displayValue,
  onChange,
}) => {
  const days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const months = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  var moment = require("moment-timezone");
  //Функция для форматирования даты
  const setFormattedDateForApi = (event) => {
    const timeTz = moment.tz.guess();

    const time = moment().tz(timeTz).format("THH:mm:ss");

    onChange(event.format(displayFormat));
    onSelectDate(event.format(apiFormat) + time);
  };

  return (
    <div>
      <DatePicker
        render={<CustomInput placeholder={placeholder} disabled={disabled} />}
        disabled={disabled}
        value={displayValue}
        weekDays={days}
        months={months}
        weekStartDayIndex={1}
        onOpenPickNewDate={false}
        format={displayFormat}
        minDate={minDate}
        maxDate={new Date(Date.now() + 3600 * 1000 * 24 * 119)}
        editable={editable && false}
        onChange={setFormattedDateForApi}
      />
    </div>
  );
};

const CustomInput = ({
  openCalendar,
  value,
  handleValueChange,
  placeholder,
  disabled,
}) => {
  return (
    <div className="railwayDate" onClick={openCalendar}>
      <input
        disabled={disabled}
        style={{ border: "none" }}
        placeholder={placeholder}
        onFocus={openCalendar}
        value={value}
        onChange={handleValueChange}
      />
    </div>
  );
};
