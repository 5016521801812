import { Modal } from 'react-bootstrap';

export const CustomDialog = props => {
  const { title, body, actions, size = 'md', ...restOfProps } = props;
  return (
    <Modal
      {...restOfProps}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ borderRadius: '10px' }}
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <Modal.Body
        style={{
          overflowY: 'scroll',
          maxHeight: '500px',
        }}
      >
        {body}
      </Modal.Body>
      <Modal.Footer>{actions}</Modal.Footer>
    </Modal>
  );
};
