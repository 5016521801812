import { Card, Row, Stack } from 'react-bootstrap';
import { ReactComponent as InfoSVG } from 'bootstrap-icons/icons/info-circle.svg';
import { useState } from 'react';

export const TransliterationRulesCard = props => {
  const { onRulesClick } = props;
  const [isRulesHovered, setIsRulesHovered] = useState(false);

  return (
    <Row className="passenger-form__complete-name">
      <Card>
        <Card.Body>
          <Stack direction="horizontal" gap={2} style={{ alignItems: 'center' }}>
            <InfoSVG />
            <span>
              Обратите внимание, при транслитерации ФИО на латиницу следует соблюдать{' '}
              <span
                style={{
                  cursor: 'pointer',
                  color: 'red',
                  textDecoration: isRulesHovered ? 'underline' : 'none',
                }}
                onMouseEnter={() => setIsRulesHovered(true)}
                onMouseLeave={() => setIsRulesHovered(false)}
                onClick={onRulesClick}
              >
                Правила транслитерации
              </span>
              .
            </span>
          </Stack>
        </Card.Body>
      </Card>
    </Row>
  );
};
